import React from 'react';

export default function AboutPage () {

    return (
        <div>
            Kweej - fun game for all
            <div className="text-xs">
            Attributions : <a href="https://www.freepik.com/vectors/hand">Hand vector created by freepik - www.freepik.com</a>

            </div>

        </div>
    )
}
