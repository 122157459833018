import { action, observable, decorate } from "mobx";

import { livesState } from "./livesState";

class QuestionState {

  sentence = "";
  result = null;

  constructor() {
    this.result = null;
    this.sentence = "";
  }

  reset() {
    this.result = null;
    this.sentence = "";
    livesState.lives = 5;
  }

  setSentence(sentence) {
    this.sentence = sentence;
    livesState.resetLives();
  }

}

decorate(QuestionState, {
    sentence: observable,
    result: observable,
    reset: action,
    setSentence: action
  })

export const questionState = new QuestionState();
