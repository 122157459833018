import React from 'react';

export default function ChatHeader() {
    return (
        <div className="z-20 flex flex-grow-0 flex-shrink-0 w-full pr-3 bg-white border-b">

            {/* <!-- Name and online --> */}
            <div className="flex flex-col px-4 py-2 justify-center flex-1 overflow-hidden cursor-pointer">
                <div className="overflow-hidden text-xl font-medium leading-tight whitespace-no-wrap">Kweej chat</div>
                <div className="overflow-hidden text-sm leading-tight text-gray-700 whitespace-no-wrap">Chat</div>
            </div>
        </div>
    );
}