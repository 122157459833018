import React from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';


export default function PodiumWinners() {
    const gameState = useSelector(state => state.gameState);
    let leaderboard = _.orderBy(gameState.playersList, ['score', 'nickname'], ['desc', 'asc']);

    let winner = leaderboard[0];
    let runnerUp = leaderboard[1];
    let secondRunnerUp = leaderboard[2];

    return (

        <div>

            <div class="block p-5 text-center">
                <p className="ext-3xl md:text-5xl text-center inline-block">Game Over</p>
            </div>

            <div className="flex justify-center items-end pt-12">

                {runnerUp && <div className="flex flex-col mx-2">

                    <div>
                        <img className="w-20 h-20" src={runnerUp.avatar} alt="runnerup" />

                    </div>

                    <div className="w-24 h-48 bg-purple-600 flex flex-col text-white text-center justify-between">
                        <span className="text font-medium pt-1">{runnerUp.nickname}</span>
                        <span className="text-4xl text-yellow-300 font-bold">#2</span>
                        <span className="text-2xl font-medium pb-1">{runnerUp.score}</span>
                    </div>

                </div>}


                {winner && <div className="flex flex-col mx-2">
                    <div className="flex justify-center">
                        <img className="w-20 h-20 animate-bounce" src={winner.avatar} alt="winner" />
                    </div>

                    <div className="w-24 h-64 bg-purple-600 flex flex-col text-white text-center justify-between">
                        <span className="text font-medium pt-1">{winner.nickname}</span>
                        <span className="text-4xl text-yellow-300 font-bold">#1</span>
                        <span className="text-2xl font-medium pb-1">{winner.score}</span>
                    </div>

                </div>}


                {secondRunnerUp && <div className=" flex flex-col mx-2">

                    <div>
                        <div className="w-20 h-20 rounded-full mb-4 bg-transparent">
                            <img src={secondRunnerUp.avatar} alt="2ndRunner" />
                        </div>
                    </div>

                    <div className="w-24 h-28 bg-purple-600 flex flex-col text-white text-center justify-between">
                        <span className="text font-medium pt-1">{secondRunnerUp.nickname}</span>
                        <span className="text-4xl text-yellow-300 font-bold">#3</span>
                        <span className="text-2xl font-medium pb-1">{secondRunnerUp.score}</span>
                    </div>

                </div>}

            </div>
        </div>

    )

} 