import React, { useState } from 'react';
import { Upload } from 'antd';
import 'antd/lib/upload/style/index.css'


const QuestionImages = ({fileList, setFileList}) => {

  const [uploadedImageURL, setUploadedImageURL] = useState(null);

  const onChange = async ({ file, fileList : newFileList }) => {

    console.log("onChange", file)

    setFileList(newFileList);

    let src = file.url;
    if (!src) {
      src = await new Promise(resolve => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });

    }
    if(fileList.length > 0) setUploadedImageURL(src);

  };


  return (

    <div className="border-2 border-gray-200 flex flex-col p-5 flex-col items-center justify-center "
      id="asset-section"
      style={{ minHeight: "250px" }}
    >

      {uploadedImageURL &&
        <a className="flex h-64 justify-center relative w-full max-w-sm flex flex-shrink-0">
          <img src="absolute object-contain bg-black" src={uploadedImageURL} alt="questionimage"></img>

        </a>}
      <Upload
        action="http://localhost:3030/photo"
        listType="text"
        fileList={fileList}
        onRemove={(file) => {
          console.log("onRemove", file);
          setUploadedImageURL(null);
          setFileList([]);
        }}
        showUploadList={true}
        onChange={onChange}
        customRequest={({file, onSuccess}) => {


          setTimeout(() => {
            onSuccess("ok");
          }, 0);
        //   console.log('options', options)
        //   const data = new FormData()
        //   data.append('file', options.file)
        //   const config = {
        //     "headers": {
        //       "content-type": 'multipart/form-data; boundary=----WebKitFormBoundaryqTqJIxvkWFYqvP5s',
        //       "Accept" : "application/json"
        //     }
        //   }
        //   axios.post(options.action, data, config).then((res) => {
        //     options.onSuccess(res.data, options.file)
        //   }).catch((err) => {
        //     console.log(err)
        //   })

        }
      }
      >
        {fileList.length < 1 &&
          <div className="flex h-full w-full bg-gray-200">
            Click here to upload any images
        </div>}
      </Upload>


    </div>
  );
};

export default QuestionImages;