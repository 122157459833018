import React, { useRef, useState } from 'react';
import { CopyOutlined } from '@ant-design/icons';


export default function TextWithCopyLink({ text }) {

    const [copySuccess, setCopySuccess] = useState('');
    const textAreaRef = useRef(null);

    function copyToClipboard(e) {
        textAreaRef.current.select();
        document.execCommand('copy');
        // This is just personal preference.
        // I prefer to not show the whole text area selected.
        e.target.focus();
        setCopySuccess('Copied!');
    };

    return (
        <div className="flex px-5 py-10 w-full flex-row items-center w-full gap-1">

            <input className="w-full text-right" ref={textAreaRef}
                value={text}
            />
            {
                document.queryCommandSupported('copy') &&
                <div>
                    <CopyOutlined onClick={copyToClipboard} />
                    {copySuccess}
                </div>
            }
        </div>
    );
}