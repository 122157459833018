import React, { useState, useEffect } from 'react';

import { useSelector } from 'react-redux';

import TimerCountdown from './TimerCountdown';
import { isMobile } from 'react-device-detect';
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";


import Leaderboard from './Leaderboard';
import Chat from './Chat/Chat'


// Hook
function useWindowSize() {
    const isClient = typeof window === 'object';

    function getSize() {
        return {
            width: isClient ? window.innerWidth : undefined,
            height: isClient ? window.innerHeight : undefined
        };
    }

    const [windowSize, setWindowSize] = useState(getSize);

    useEffect(() => {
        if (!isClient) {
            return false;
        }

        function handleResize() {
            setWindowSize(getSize());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []); // Empty array ensures that effect is only run on mount and unmount

    return windowSize;
}

export default function QuestionHeader({ displayLeaderboard, displayChat, displayTimer, title }) {

    const gameState = useSelector(state => state.gameState);
    const windowSize = useWindowSize();

    const [leaderboardModalVisible, setLeaderboardModalVisible] = useState(false);
    const showLeaderboardModal = () => { setLeaderboardModalVisible(true); };



    // const contextualChatDisplay = () => {
    //     if((windowSize.width >= 768) || !chatModalVisible) return false;
    //     if(!gameState.isGameStarted) return true;
    // }

    const RoundInfo = ({ title }) => {
        return (
            <div className="flex items-center">
                {!title ?
                    <span>Round {gameState.currentRoundNo + 1} of {gameState.totalRounds} | Question {gameState.currentQuestionNo + 1} of {gameState.currentRound.totalQuestionsInRound} </span>
                    : <span>{title}</span>
                }
            </div>
        );
    }

    //TODO timer
    const Timer = ({ endTimestamp }) => {

        let secondsLeft = (endTimestamp - Date.now()) / 1000;

        return (
            <div className="bg-black flex justify-center items-center w-16 h-8 rounded-full">
                <TimerCountdown timeForQuestion={secondsLeft} />
            </div>

        );
    }


    return (
        <div className="py-2 px-4 flex flex-row w-full justify-between items-center h-12">
            {displayLeaderboard && (windowSize.width < 768) &&
                <button
                    className="mx-1 flex justify-center items-center p-0 w-8 h-8 bg-purple-400 rounded-full shadow transition ease-in duration-200 focus:outline-none transform transition duration-300 ease-in-out select-none"
                    onClick={showLeaderboardModal}
                >
                    <i className="fas fa-trophy" color="white"></i>
                </button>}

            <RoundInfo title={title} />
            {displayTimer && <Timer endTimestamp={gameState.currentQuestion.endTimestamp} />}

            <SwipeableDrawer
                disableSwipeToOpen={false}
                hysteresis={0.1}
                anchor="left"
                minFlingVelocity={250}
                swipeAreaWidth={10}
                open={leaderboardModalVisible}
                onClose={() => setLeaderboardModalVisible(false)}
                onOpen={() => setLeaderboardModalVisible(true)}
            >


                <div style={{ width: windowSize.width * 0.8 }}>

                    <Leaderboard />
                </div>
            </SwipeableDrawer>

        </div>
    );
}

