import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import GuessWords from "./components/GuessWords";
import Keyboard from "./components/Keyboard";
import Lives from "./components/Lives"
import { questionState } from "./state/questionState";
import { hangmanGuessState } from "./state/hangmanGuessState";

const HangmanQuestion = ({ answer, submitAnswer, isPlayer }) => {

  useEffect(() => {
    hangmanGuessState.resetGuess();
    questionState.setSentence(answer);
  }, []);

  useEffect(() => {
    hangmanGuessState.addStatusEventListener((status) => {
      if (status === "correct") {
        submitAnswer({
          answerType: "TEXT",
          answerValue: answer
        });
      } else if (status === "incorrect") {

        submitAnswer({
          answerType: "TEXT",
          answerValue: "incorrect"
        });
      }
    })
  }, []);

  return (


    isPlayer ? (
      <div className="flex flex-col w-full justify-center items-center">
        <GuessWords />
        <p className="h-10"></p>
        <Keyboard />
        <Lives />

      </div>
    ) : (
      <p>Player for : {answer}</p>
    )

  );
};

export default observer(HangmanQuestion);
