import React, { useState } from 'react';
import _, { filter } from 'lodash';

const KweejFilterGroup = ({ filterOptions, onSubmit }) => {

    const [filters, setFilters] = useState({});

    const onUpdateFilters = (filterKey, filter) => {
        let newFilters = { ...filters };
        if(filter.length == 0) {
            delete newFilters[filterKey];
        }
        else newFilters[filterKey] = filter;
        setFilters(newFilters);
    }

    return (
        <div id="filter-group-container" className="flex flex-col overflow-y-auto">
            {Object.keys(filterOptions).map(filterOptionKey => (
                <KweejFilter filterKey={filterOptionKey} filterOptions={filterOptions[filterOptionKey]} onUpdate={onUpdateFilters} />
            ))}

            <button className="btn btn-primary text-sm" onClick={() => onSubmit(filters)}>Apply filters</button>
        </div>
    )

}

const KweejFilter = ({ filterKey, filterOptions, onUpdate }) => {

    const [selectedOptions, setSelectedOptions] = useState([]);

    const handleOptionClick = (e) => {

        let newSelectedOptions = null;
        let newValue = null;
        if(filterOptions.valueType === 'boolean') {
            newValue = e.target.name === "true"? true : false;
        } else {
            newValue = e.target.name;
        }


        if (e.target.checked) {
            newSelectedOptions = [...selectedOptions, newValue];

        } else {

            newSelectedOptions = selectedOptions.filter((item) => {
                return item != newValue;
            })
            
        }

        console.log(newSelectedOptions);
        setSelectedOptions(newSelectedOptions);

        onUpdate(filterKey, newSelectedOptions);

    }

    return (
        <div className="p-2" id={`filter-option-container-${filterKey}`}>
            {filterOptions.length && <p className="text-xl">{filterOptions.title}</p>}
            {filterOptions.options.map(option => (
                <label className="block text-gray-600 font-bold">
                    <input className="form-checkbox mr-2 leading-tight" name={option.value} type="checkbox" key={option.value} onClick={handleOptionClick}
                        checked={selectedOptions.includes(option.value)}
                    />
                    <span className="text-sm">
                        {option.label}
                    </span>
                </label>

            ))}
        </div>
    )

}

export {KweejFilterGroup, KweejFilter};