import io from "socket.io-client";
import {getServerDomain} from './util';
import { useDispatch } from 'react-redux';
import store from './ducks/Store'
import { setPlayer, updateGameState, selectedPin, handleNickname, setHost, newChatMessage, setAnswerSubmitted, setChatModalVisible  } from './ducks/Reducer';
import { v4 as uuidv4 } from 'uuid';


const dispatch = store.dispatch;
let socket = null;

const connectSocket = () => {
    socket = io(getServerDomain() + '/');

    socket.on('room-joined', ({ player, gameState }) => {
        dispatch(updateGameState(gameState));
    });

    return socket;

}

const getSocket = () => {

    if(socket) {
        return socket;
    } else {
        return connectSocket();
    } 

}

const playerJoin = (pin, nickname, avatar) => {

    let socket = getSocket();
    let uuid = sessionStorage.getItem(pin) || uuidv4();

    return new Promise((resolve, reject) => {
        socket.emit('player-joined', { pin, nickname, avatar, uuid }, (success) => {
            if(!success) reject ("Game not started yet by host. Try again after some time");


            sessionStorage.setItem(pin, uuid);
            dispatch(handleNickname(nickname));
            dispatch(selectedPin(pin));
            //dispatch(setHost(false));
            dispatch(setPlayer(true));

            resolve("success");
        })
    })

    // socket.emit('player-joined', {
    //     pin, nickname, "avatar": avatar, uuid
    // }, (success) => {
    //     if (!success) {
    //         alert("Game not started yet by host. Try again after some time");
    //         // setPlayerLoginBtnBlocked(false);
    //         //window.location.assign("/")
    //     } else {
    //         // setPlayerLoginBtnBlocked(false);

    //         sessionStorage.setItem(pin, uuid);
    //         dispatch(handleNickname(nickname));
    //         dispatch(selectedPin(pin));
    //         dispatch(setHost(false));
    //         dispatch(setPlayer(true));
            // props.goFull();
            // setRedirect(true);

    //     }
    // });
}

export {getSocket as default, playerJoin};