import { observable, decorate } from "mobx";

class LivesState {
  lives = 5;

  resetLives() {
    this.lives = 5;
  }

  decreaseLives() {
    this.lives--;
  }
}

decorate(LivesState, {
  lives : observable,
  resetLives : observable,
  decreaseLives : observable
})

export const livesState = new LivesState();
