import defaultAvatar from '../../assets/avatars/avatar1.svg';

const initialState = {
    socket: null,
    quiz: {},
    nickname: '',
    selectedPin: '',
    quizToEdit: {},
    isHost: false,
    isPlayer: false,
    gameState: {
        playersList: [],
        isGameStarted: false,
        isGameOver: false,
        currentQuestion: {
            isQuestionOver: false
        },

    },
    answerSubmitted: false,
    solution: "",
    messages: [],
    chatModalVisible: false,
    avatar: defaultAvatar,
    remoteStreams: []
}

const SELECTED_QUIZ = 'SELECTED_QUIZ'
const NEW_NICKNAME = 'NICKNAME'
const SELECTED_PIN = 'SELECTED_PIN'
const QUIZ_TO_EDIT = 'QUIZ_TO_EDIT'
const GAMESTATE = 'GAMESTATE'
const HOST_STATUS = 'HOST_STATUS'
const PLAYER_STATUS = 'PLAYER_STATUS'
const NEW_CHAT_MESSAGE = 'NEW_CHAT_MESSAGE'
const CHAT_MODAL_VISIBLE = 'CHAT_MODAL_VISIBLE'
const SET_ANSWER_SUBMITTED = 'SET_ANSWER_SUBMITTED'
const SET_SOLUTION = 'SET_SOLUTION'
const SET_AVATAR = 'SET_AVATAR'

// const REMOTE_STREAM_ADD = 'REMOTE_STREAM_ADD'


export default function reducer(state = initialState, action) {
    switch (action.type) {
        case SELECTED_QUIZ:
            return Object.assign({}, state, { quiz: action.payload })
        case NEW_NICKNAME:
            return Object.assign({}, state, { nickname: action.payload })
        case SELECTED_PIN:
            return Object.assign({}, state, { selectedPin: action.payload })
        case QUIZ_TO_EDIT:
            return Object.assign({}, state, { quizToEdit: action.payload })
        case GAMESTATE:
            let recdGameState = action.payload;
            if ((!state.gameState.isGameStarted && recdGameState.isGameStarted) || //new game
                (state.gameState.currentQuestion.isQuestionOver && !recdGameState.currentQuestion.isQuestionOver) //new question
            ) recdGameState.currentQuestion.endTimestamp = Date.now() + 1000 * recdGameState.currentQuestion.secondsLeft 
            else recdGameState.currentQuestion.endTimestamp = state.gameState.currentQuestion.endTimestamp;
            return Object.assign({}, state, { gameState: recdGameState })
        case HOST_STATUS:
            return Object.assign({}, state, { isHost: action.payload })
        case PLAYER_STATUS:
            return Object.assign({}, state, { isPlayer: action.payload })
        case NEW_CHAT_MESSAGE:
            return { ...state, messages: [...state.messages, action.payload] }
        case CHAT_MODAL_VISIBLE:
            return Object.assign({}, state, { chatModalVisible: action.payload })
        case SET_ANSWER_SUBMITTED:
            return Object.assign({}, state, { answerSubmitted: action.payload })
        case SET_SOLUTION:
            return Object.assign({}, state, { solution: action.payload })
        case SET_AVATAR:
            return Object.assign({}, state, { avatar: action.payload })

        // case REMOTE_STREAM_ADD:
        //     return {...state, remoteStreams : [...state.remoteStreams, {"socketID" : action.payload.socketID, "remoteStream" : action.payload.remoteStream}]} 
        default:
            return state;
    }
}


export function selectedQuiz(quiz) {
    return {
        type: SELECTED_QUIZ,
        payload: quiz
    }
}
export function handleNickname(nickname) {
    return {
        type: NEW_NICKNAME,
        payload: nickname
    }
}
export function selectedPin(pin) {
    return {
        type: SELECTED_PIN,
        payload: pin
    }
}
export function editingQuiz(quiz) {
    return {
        type: QUIZ_TO_EDIT,
        payload: quiz
    }
}
export function updateGameState(gameState) {
    return {
        type: GAMESTATE,
        payload: gameState
    }
}
export function setHost(isHost) {
    return {
        type: HOST_STATUS,
        payload: isHost
    }
}
export function setPlayer(isPlayer) {
    return {
        type: PLAYER_STATUS,
        payload: isPlayer
    }
}
export function newChatMessage(message) {
    return {
        type: NEW_CHAT_MESSAGE,
        payload: message
    }
}
export function setChatModalVisible(chatModalVisible) {
    return {
        type: CHAT_MODAL_VISIBLE,
        payload: chatModalVisible
    }
}

export function setAnswerSubmitted(answerSubmitted) {
    return {
        type: SET_ANSWER_SUBMITTED,
        payload: answerSubmitted
    }
}

export function setSolution(solution) {
    return {
        type: SET_SOLUTION,
        payload: solution
    }
}

export function setAvatar(avatar) {
    return {
        type: SET_AVATAR,
        payload: avatar
    }
}
// export function addRemoteStream(socketID, remoteStream) {
//     return {
//         type: REMOTE_STREAM_ADD,
//         payload: {socketID, remoteStream}
//     }
// }