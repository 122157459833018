import React from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { Flipper, Flipped } from "react-flip-toolkit";
import FlipMove from 'react-flip-move'
import { observer } from 'mobx-react-lite';


const PlayersAnsweredPanel = () => {

    const gameState = useSelector(state => state.gameState);

    let playersAnsweredList = gameState.currentQuestion.playersWhoHaveAnswered;

    return (
        <Flipper flipKey={JSON.stringify(playersAnsweredList)}>

        <div className="px-4 bg-gray-200 flex flex-row items-center overflow-hidden h-16">

            {playersAnsweredList.map(player => (
                <div className="flex flex-col items-center text-sm text-center ml-4 flex-shrink-0">
                        <Flipped flipId={1}>
                        <div>

                            <img className="h-8 w-10" src={player.avatar} alt="user" />
                            <span className="text-xs">{player.nickname}</span>
                        </div>

                        </Flipped>
                </div>

            ))}
        </div>
        </Flipper>
    )
}

export default observer(PlayersAnsweredPanel);