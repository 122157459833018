import React, { useState } from 'react';
import OtpInput from 'react-otp-input';


export default function CodeQuestion(props) {

    const [code, setCode] = useState('');

    const handleInput = (otp) => {
        setCode(otp);
    }

    const handleClick = (submitAnswer) => {
        submitAnswer({
            answerType: "TEXT",
            answerValue: code
        });
    }

    return (
        <div className="flex flex-col " id='code-question' >
            {props.isPlayer &&
                <div id="code-input-section">
                    <OtpInput
                        onChange={handleInput}
                        numInputs={props.codeLength}
                        separator={<span>-</span>}
                        inputStyle={{
                            "fontSize": "20px"
                        }}
                        isInputNum={props.isInputNum}
                    />

                    <button className="shadow bg-purple-600 hover:bg-purple-500 focus:shadow-outline focus:outline-none text-white py-2 px-4 rounded"
                        type="button"
                        onClick={() => { if (props.isPlayer) handleClick(props.submitAnswer) }} >
                        Submit
                    </button>
                </div>
            }
        </div>
    )
}

CodeQuestion.defaultProps = {
    codeLength: 10,
    isInputNum: false
}