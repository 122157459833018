import _ from 'lodash'
import avatar1 from './avatar1.svg'
import avatar2 from './avatar2.svg'
import avatar3 from './avatar3.svg'
import avatar4 from './avatar4.svg'
import avatar5 from './avatar5.svg'
import avatar6 from './avatar6.svg'
import avatar7 from './avatar7.svg'
import avatar8 from './avatar8.svg'

export default _.shuffle([avatar1, avatar2, avatar3, avatar4, avatar5, avatar6, avatar7, avatar8]);