import React from 'react';
import PropTypes from 'prop-types';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

import './Letters.css';
import { Letter } from './Letter/Letter';

const Letters = ({letterNo, passedLetters, clicked, dragActive, dragEnd}) => {
    
    passedLetters = Array.from(passedLetters);
    return (

        <DragDropContext onDragEnd={dragEnd}>
            <section className="Letters">
                <Droppable droppableId={"drop"+letterNo} direction="horizontal">
                    {(provided, snapshot) => (
                        <ul
                            className="LettersContainer"
                            ref={provided.innerRef}
                            {...provided.droppableProps}

                        >
                            {
                                passedLetters.map((letter, i) => (
                                    <Letter
                                        /*eslint-disable react/jsx-props-no-spreading*/
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        dragActive={dragActive}
                                        clicked={clicked}
                                        letter={letter}
                                        index={i}
                                        // letterNo={letterNo}
                                        key={letter.id}
                                    />
                                ))
                            }
                            {provided.placeholder}
                        </ul>
                    )}
                </Droppable>
            </section>
        </DragDropContext>
    )
};

Letters.propTypes = {
    passedLetters: PropTypes.array.isRequired,
    clicked: PropTypes.func,
    dragActive: PropTypes.bool.isRequired,
    dragEnd: PropTypes.func,
};

Letters.defaultProps = {
    dragEnd: () => {},
    clicked: () => {},
    passedLetters: () => [{value:'K', id:'1234', active:true}]
};

export { Letters };
