
import React from 'react'

import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import './FloatChatBox.css'

import { getHashColor, useWindowSize } from '../../../utils/util'

export const FloatChatMessage = ({ sender, message }) => {
    return (
        <div className="flex items-center">
            <span className="text-sm">{message} : </span>
            <span className={`font-bold text-sm text-${getHashColor(sender)}`}>{sender}</span>
        </div>
    )
}

export const FloatChatBox = () => {

    const windowSize = useWindowSize();


    return (
        (windowSize.width < 768) && <div className="absolute h-40 w-40 top-0 right-0 z-20">

            <ToastContainer
                position="top-right"
                autoClose={5000}
                limit={5}
                closeButton={false}
                hideProgressBar={true}
                closeOnClick={true}
                rtl={true}
                pauseOnFocusLoss={false}
                draggable={true}
                pauseOnHover
            />

        </div>
    )
}