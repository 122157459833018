import React from "react";
import { observer } from "mobx-react-lite";

import { hangmanGuessState } from "../state/hangmanGuessState";
import './Letter.css'


const Letter = ({ isSpace, letter }) => {
  const renderLetter = () => {
    if (hangmanGuessState.correctGuess.includes(letter)) {
      return letter.toUpperCase();
    }
  };

  return (

    isSpace ? 
      <div class="w-3 h-6 md:w-4 md:h-8 m-1 bg-white opacity-100">
      </div>
      :
      <div className={'flip-card w-8 h-8 md:w-10 md:h-10 m-1 bg-transparent rounded '.concat(hangmanGuessState.correctGuess.includes(letter) ? 'flip-card-active' : '')} >
        <div class="relative flip-card-inner w-full h-full rounded">
          <div class="absolute flip-card-front bg-gray-300 w-full h-full rounded flex items-center justify-center text-xl md:text-2xl">
            -
      </div>
          <div class="absolute flip-card-back bg-purple-200 w-full h-full rounded flex items-center justify-center text-xl md:text-2xl">
            {letter.toUpperCase()}
          </div>
        </div>
      </div>
  );
};

export default observer(Letter);
