import React, { Component } from 'react';
import { connect } from 'react-redux';
import {setChatModalVisible} from '../../ducks/Reducer'
import { announceSplashState } from './AnnounceSplashState'
import UIfx from 'uifx'
import chimeAudio from '../../../assets/sounds/chime.mp3'
import pushAudio from '../../../assets/sounds/push.mp3'
import correctAnswerAudio from '../../../assets/sounds/CorrectAnswer.mp3'
import wrongAnswerAudio from '../../../assets/sounds/WrongAnswer.mp3'

import { message } from 'antd';
import 'antd/lib/message/style/index.css'


const chime = new UIfx(chimeAudio);
const push = new UIfx(pushAudio);
const correctAnswerSound = new UIfx(correctAnswerAudio);
const wrongAnswerSound = new UIfx(correctAnswerAudio);

//https://developers.google.com/web/fundamentals/native-hardware/fullscreen

//https://codepen.io/akikoo/pen/xdaic?__cf_chl_jschl_tk__=3918f8a294512f90a7a569fc9f70940925795bc3-1596907882-0-AUAghcEwr2VHFoZGOD6WKzMFCyRN3hQcTMarfSWmVazrMUcAAVJkWMehsMCBkearbXRqsD5SRqYQBQGfbOai1Sj-Y9vnVT8pwnx4Iu7JpE4H4VD74PbT77zj9dBi_3QAqx7pkvTFjh2jCaeQ9ynPjFvvpo_RMje_8xPzDPv1QTH3qs7InBA6s8a3nsYnaSfexVwF--REzX0fSUhy6FNB6zHgvOIUydRF5BcAWPj9pcYv1kjCW0fDMb_PWV-sW92qvVI3n-7SyY3L3f7JAdasr9I2L5ABgbN5JPF9JIj2WMN1RWpL-5I2VAy03mQvTEuOPydUtznNM_Y-pSrlB_LNufvrQPeiYlqVQGrnaaX3U0kG
// (function (win) {
//     var doc = win.document;

//     // If there's a hash, or addEventListener is undefined, stop here
//     if (!location.hash && win.addEventListener) {

//         //scroll to 1
//         window.scrollTo(0, 1);
//         var scrollTop = 1,
//             getScrollTop = function () {
//                 return win.pageYOffset || doc.compatMode === "CSS1Compat" && doc.documentElement.scrollTop || doc.body.scrollTop || 0;
//             },

//             //reset to 0 on bodyready, if needed
//             bodycheck = setInterval(function () {
//                 if (doc.body) {
//                     clearInterval(bodycheck);
//                     scrollTop = getScrollTop();
//                     win.scrollTo(0, scrollTop === 1 ? 0 : 1);
//                 }
//             }, 15);

//         win.addEventListener("load", function () {
//             setTimeout(function () {
//                 //at load, if user hasn't scrolled more than 20 or so...
//                 if (getScrollTop() < 20) {
//                     //reset to hide addr bar at onload
//                     win.scrollTo(0, scrollTop === 1 ? 0 : 1);
//                 }
//             }, 0);
//         });
//     }
// })(this);


// hideAddressBar = () => {
//     if (navigator.userAgent.match(/Android/i)) {
//         window.scrollTo(0, 0); // reset in case prev not scrolled
//         var nPageH = $(document).height();
//         var nViewH = window.outerHeight;
//         if (nViewH > nPageH) {
//             nViewH = nViewH / window.devicePixelRatio;
//             $('BODY').css('height', nViewH + 'px');
//         }
//         window.scrollTo(0, 1);
//     } else {
//         addEventListener("load", function () {
//             setTimeout(hideURLbar, 0);
//             setTimeout(hideURLbar, 500);
//         }, false);
//     }
//     function hideURLbar() {
//         if (!pageYOffset) {
//             window.scrollTo(0, 1);
//         }
//     }
//     return this;
// }

class StateMonitor extends Component {

    // componentDidMount() {

    //     announceSplashState.init({ mainTitle1: "Game", mainTitle2: "Started", 
    //         messageParas:[{title:"This is \ntitle para", text:"This is text"},{title:"Godha hai tu", text:"Abooodii mahaan"}]
    //     }, 
    //         true, false, 10000000);

    // }

    handleStateChangeNewQuestion (msg) {

        //push.play();

        if(this.props.chatModalVisible) {
            console.log("Next question started");
            this.props.dispatch(setChatModalVisible(false));

            message.info(msg);
        }

    }

    componentDidUpdate(prevProps, prevState) {


        let prevGameState = prevProps.gameState;
        let thisGameState = this.props.gameState;

        if (thisGameState.isGameStarted && !prevGameState.isGameStarted) {
            //chime.play();
            this.handleStateChangeNewQuestion("Game started");

        }

        // if (thisGameState.isGameOver && !prevGameState.isGameOver) {
        //     announceSplashState.init({ mainTitle1: "Game", mainTitle2: "Over" }, true, false, 2000);
        // }

        if (prevGameState.currentQuestion.isQuestionOver && !thisGameState.currentQuestion.isQuestionOver) {
            this.handleStateChangeNewQuestion("Next question started");
        }

        // if (prevGameState.currentRoundNo < thisGameState.currentRoundNo) {
        //     announceSplashState.init({ mainTitle1: "Round ".concat(thisGameState.currentRoundNo + 1), mainTitle2: thisGameState.currentRound.title }, true, false, 2000);
        // }

    }

    render() {
        return (
            null
        );
    }
}

function mapStateToProps(state) {
    return {
        gameState: state.gameState,
        chatModalVisible: state.chatModalVisible
    }
}

export default connect(mapStateToProps)(StateMonitor)