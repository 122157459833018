/* eslint-disable */ 

import React from 'react';

import "./Submit.css";

const Submit = ({ onSubmit, onCancel, showCancel }) => (
    <section className="Submit">
        <button
            className="shadow bg-purple-600 hover:bg-purple-500 focus:shadow-outline focus:outline-none text-white my-4 py-2 px-4 rounded"
            onClick={onSubmit}
            type="button"
        >
            Submit
        </button>
    </section>
);

export { Submit };
