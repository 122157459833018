import React, { useState, useEffect, useRef } from 'react';

import QuestionImages from './QuestionImages/QuestionImages'
import AnswerFormatSelector from './AnswerFormatSelector';
import QuestionTypeSelector from './QuestionTypeSelector';

import axios from 'axios';

import { message } from 'antd';
import 'antd/lib/message/style/index.css'

import Radio from '@material-ui/core/Radio';

import {getServerDomain} from '../../utils/util'

import { setHost } from '../../utils/ducks/Reducer';

import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';


export default function QuestionEditor(props) {

    const [blocked, setBlocked] = useState(false);

    //Form stuff
    const [questionTypes, setQuestionTypes] = useState([]);
    const [answerFormats, setAnswerFormats] = useState([]);

    const [questionTitle, setQuestionTitle] = useState("");
    const [answerFormat, setAnswerFormat] = useState("MCQ2");
    const [questionType, setQuestionType] = useState("TRIVIA");

    const [hostHint, setHostHint] = useState("");
    const [correctAnswer, setCorrectAnswer] = useState("");

    const [questionDetails, setQuestionDetails] = useState(null);

    const [fileList, setFileList] = useState([]);

    //Radio stuff
    const [selectedRadioValue, setSelectedRadioValue] = useState('1');
    const choice1InputEl = useRef(null);
    const choice2InputEl = useRef(null);
    const choice3InputEl = useRef(null);
    const choice4InputEl = useRef(null);

    const handleRadioChange = (e) => {
        setSelectedRadioValue(e.target.value);
        setCorrectMCQAnswer(e.target.value);
    };

    const setCorrectMCQAnswer = (selectedOption) => {

        switch (selectedOption) {
            case '1':
                setCorrectAnswer(choice1InputEl.current.value);
                break;
            case '2':
                setCorrectAnswer(choice2InputEl.current.value);
                break;
            case '3':
                setCorrectAnswer(choice3InputEl.current.value);
                break;
            case '4':
                setCorrectAnswer(choice4InputEl.current.value);
                break;
            default:
                setCorrectAnswer("");
                break;
        }
        console.log('correctAnswer :>> ', correctAnswer);

    }

    const handleMCQInputChange = (e, choiceNo) => {

        switch (choiceNo) {
            case 1:
                setQuestionDetails({ ...questionDetails, choice1: e.target.value });
                break;
            case 2:
                setQuestionDetails({ ...questionDetails, choice2: e.target.value });
                break;
            case 3:
                setQuestionDetails({ ...questionDetails, choice3: e.target.value });
                break;
            case 4:
                setQuestionDetails({ ...questionDetails, choice4: e.target.value });
                break;

        }

        console.log('questionDetails :>> ', questionDetails);

        setCorrectMCQAnswer(selectedRadioValue);
    }

    const handleSubmit = () => {

        setBlocked(true);

        console.log(fileList);

        let uploadURLEndpoint = getServerDomain() + "/photo";

        const data = new FormData()
        if (fileList && fileList[0]) data.append('file', fileList[0].originFileObj)

        data.append('questionType', questionType);
        data.append('questionTitle', questionTitle);
        data.append('answerFormat', answerFormat);
        data.append('hostHint', hostHint);
        data.append('correctAnswer', correctAnswer);
        data.append('questionDetails', JSON.stringify(questionDetails));

        console.log('questionDetails :>> ', questionDetails);

        const config = {
            "headers": {
                "content-type": 'multipart/form-data; boundary=----WebKitFormBoundaryqTqJIxvkWFYqvP5s',
                "Accept": "application/json"
            }
        }

        axios.post(uploadURLEndpoint, data, config).then(
            (res) => {
                setBlocked(false);
                console.log("Response is >>", res);
                message.success(res.data.statusMsg);
            }).catch((err) => {
                setBlocked(false);
                console.log("Error is >>", err)
                message.error("Something went wrong, try again");
            })


    }


    const getAnswerFormatComponent = (answerFormat) => {

        switch (answerFormat) {

            case 'TEXT':
                return <div className="flex flex-col">

                    <input
                        className="input-field"
                        placeholder="Enter host hint if any"
                        type="text"
                        onChange={handleHostHintChange}
                    />
                </div>
            case 'HANGMAN':
                return <input
                    className="input-field"
                    placeholder="Enter the word/phrase to solve"
                    type="text"
                    onChange={handleHangmanChange}
                />
            case 'VOICE':
                return <div className="flex flex-col">

                    <input
                        className="input-field"
                        placeholder="Enter host hint if any"
                        type="text"
                        onChange={handleHostHintChange}

                    />
                </div>

            case 'ANAGRAM':
                return <input
                    className="input-field"
                    placeholder="Enter the word/phrase to solve"
                    type="text"
                    onChange={handleAnagramChange}

                />
            case 'MCQ4':
                return <div className="flex flex-col">

                    <div class="flex flex-row flex-wrap justify-evenly">
                        <input
                            className="input-field"
                            ref={choice1InputEl}
                            placeholder="Choice 1"
                            type="text"
                            onChange={(e) => { handleMCQInputChange(e, 1) }}
                        />
                        <Radio
                            checked={selectedRadioValue === '1'}
                            onChange={handleRadioChange}
                            value="1"
                            name="radio-button-choice1"
                            inputProps={{ 'aria-label': '1' }}
                        />
                        <input
                            className="input-field"
                            ref={choice2InputEl}
                            placeholder="Choice 2"
                            type="text"
                            onChange={(e) => { handleMCQInputChange(e, 2) }}

                        />
                        <Radio
                            checked={selectedRadioValue === '2'}
                            onChange={handleRadioChange}
                            value="2"
                            name="radio-button-choice1"
                            inputProps={{ 'aria-label': '2' }}
                        />
                    </div>
                    <div class="flex flex-row flex-wrap justify-evenly">
                        <input
                            className="input-field"
                            ref={choice3InputEl}
                            placeholder="Choice 3"
                            type="text"
                            onChange={(e) => { handleMCQInputChange(e, 3) }}

                        />
                        <Radio
                            checked={selectedRadioValue === '3'}
                            onChange={handleRadioChange}
                            value="3"
                            name="radio-button-choice1"
                            inputProps={{ 'aria-label': '3' }}
                        />
                        <input
                            className="input-field"
                            ref={choice4InputEl}
                            placeholder="Choice 4"
                            type="text"
                            onChange={(e) => { handleMCQInputChange(e, 4) }}

                        />
                        <Radio
                            checked={selectedRadioValue === '4'}
                            onChange={handleRadioChange}
                            value="4"
                            name="radio-button-choice1"
                            inputProps={{ 'aria-label': '4' }}
                        />
                    </div>
                </div>

            case 'MCQ2':
                return <div class="flex flex-wrap justify-evenly">

                    <div>
                        <input
                            className="input-field"
                            ref={choice1InputEl}
                            placeholder="Choice 1"
                            type="text"
                            onChange={(e) => { handleMCQInputChange(e, 1) }}

                        />
                        <Radio
                            checked={selectedRadioValue === '1'}
                            onChange={handleRadioChange}
                            value="1"
                            name="radio-button-choice1"
                            inputProps={{ 'aria-label': '1' }}
                        />

                    </div>
                    <div>
                        <input
                            className="input-field"
                            ref={choice2InputEl}
                            placeholder="Choice 2"
                            type="text"
                            onChange={(e) => { handleMCQInputChange(e, 2) }}

                        />
                        <Radio
                            checked={selectedRadioValue === '2'}
                            onChange={handleRadioChange}
                            value="2"
                            name="radio-button-choice1"
                            inputProps={{ 'aria-label': '2' }}
                        />
                    </div>


                </div>

            default:
                return <input value="Enter the correct answer" />
        }
    }


    const fetQuestionTypes = () => {
        let questionTypes = [
            { sr: 1, type: "TRIVIA", description: "Trivia question", questionTitlePrompt: "Select the correct answer", preferredAnswerFormat: "MCQ" },
            { sr: 2, type: "EMOJI", description: "Emoji solve", questionTitlePrompt: "Guess the word/phrase from the emojis and answer below", preferredAnswerFormat: "VOICE" },
            { sr: 3, type: "REVEAL", description: "Reveal image", questionTitlePrompt: "A picture will be slowly revealed. Guess who it is.", preferredAnswerFormat: "TEXT" },
            { sr: 4, type: "ANTRA", description: "Mukhda from Antra", questionTitlePrompt: "Sing the Mukhda of the song who's Antra is the text below", preferredAnswerFormat: "VOICE" },
            { sr: 5, type: "HANGMAN", description: "Hangman solve", questionTitlePrompt: "Guess the word/phrase using the keyboard letters, you have only 5 wrong guesses!", preferredAnswerFormat: "HANGMAN" },
            { sr: 6, type: "ANAGRAM", description: "Anagram solve", questionTitlePrompt: "Solve the anagram to guess the word/phrase", preferredAnswerFormat: "ANAGRAM" },
        ]

        questionTypes.sort((a, b) => { return a.sr - b.sr })
        setQuestionTypes(questionTypes);
    }


    const fetchAnswerFormats = () => {
        let answerFormats = [
            { sr: 2, type: "MCQ4", description: "MCQ with 4 options", isHostDetermined: false },
            { sr: 1, type: "MCQ2", description: "MCQ with 2 options", isHostDetermined: false },
            { sr: 3, type: "TEXT", description: "Open text answer", isHostDetermined: true },
            { sr: 4, type: "VOICE", description: "Voice answer", isHostDetermined: true },
            { sr: 5, type: "HANGMAN", description: "Hangman solve", isHostDetermined: false },
            { sr: 6, type: "ANAGRAM", description: "Anagram solve", isHostDetermined: false },

        ]

        answerFormats.sort((a, b) => { return a.sr - b.sr });

        setAnswerFormats(answerFormats);
    }

    function handleMCQChange(e, choiceNo) {

    }


    function handleAnswerFormatChange(event) {
        let value = event.target.value;

        setAnswerFormat(value);
    }

    function handleQuestionTypeChange(event) {
        let value = event.target.value;
        setQuestionType(value);
        let { questionTitlePrompt, preferredAnswerFormat } = questionTypes.find(questionType => questionType.type === value);
        setQuestionTitle(questionTitlePrompt);
        setAnswerFormat(preferredAnswerFormat);
    }

    function handleQuestionTitleChange(e) { setQuestionTitle(e.target.value) };

    function handleCorrectAnswerChange(e) { setCorrectAnswer(e.target.value) };

    // function handleHostHintChange(e) { setHostHint(e.target.value) };

    function handleHangmanChange(e) {
        let answer = e.target.value;
        setQuestionDetails({ answer: answer });
        setCorrectAnswer(answer);
        setHostHint(answer);
    }

    function handleHostHintChange(e) {
        let answer = e.target.value;
        setQuestionDetails({})
        setHostHint(answer);
    }

    function handleAnagramChange(e) {
        let sentence = e.target.value;
        setQuestionDetails({ "sentence": sentence })
        setCorrectAnswer(sentence);
        setHostHint(sentence);
    }

    function resetStates() {

        setQuestionTypes([]);
        setAnswerFormats([]);

        setQuestionTitle("");
        setAnswerFormat("TEXT");
        setQuestionType("TRIVIA");

        setHostHint("");
        setCorrectAnswer("");

        setQuestionDetails(null);

        setFileList([]);

    }


    useEffect(() => {
        fetQuestionTypes();
        fetchAnswerFormats();
    }, []);


    return (

        <div className="flex mx-5 flex-col justify-center ml-auto mr-auto lg:w-5/12 ">

            <QuestionTypeSelector selectedQuestionType={questionType} questionTypes={questionTypes} handleQuestionTypeChange={handleQuestionTypeChange} />
            <textarea autoFocus
                rows={2}
                value={questionTitle}
                onChange={handleQuestionTitleChange}
                className="w-full my-5 text-center text-xl lg:text-2xl xl:text-3xl bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-lg text-gray-700 text-center leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                type="text"
                placeholder="Enter question title" />

            <QuestionImages fileList={fileList} setFileList={setFileList} />
            <AnswerFormatSelector selectedAnswerFormat={answerFormat} answerFormats={answerFormats} handleAnswerFormatChange={handleAnswerFormatChange} />
            {getAnswerFormatComponent(answerFormat)}

            <BlockUi tag="div" blocking={blocked}>

                <button
                    className="w-full btn btn-primary"
                    onClick={handleSubmit}>
                    Submit
                </button>
            </BlockUi>
        </div>
    )
}