import React from "react";
import FlipMove from "react-flip-move";
import { observer } from "mobx-react-lite";
import { HeartTwoTone } from '@ant-design/icons';


// import { ReactComponent as Heart } from "../svg/heart.svg";

import { livesState } from "../state/livesState";

const Lives = () => {
  const renderLives = () => {

    return Array.from({ length: livesState.lives }).map((_, index) => {
      return <HeartTwoTone twoToneColor="#eb2f96" style={{fontSize:"30px"}} key={index}/>
    }
    );
  };

  return (
    <div className="right-0 bottom-0 m-4">
      <FlipMove>{renderLives()}</FlipMove>
    </div>
  );
};

export default observer(Lives);
