import React, { Component } from "react";
import MediaCapturer from "react-multimedia-capture";
import './mic.css'


export default class VoiceQuestion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            granted: false,
            rejectedReason: "",
            recording: false,
            paused: false,
        };

        this.handleGranted = this.handleGranted.bind(this);
        this.handleDenied = this.handleDenied.bind(this);
        this.handleStart = this.handleStart.bind(this);
        this.handleStop = this.handleStop.bind(this);
        this.handlePause = this.handlePause.bind(this);
        this.handleResume = this.handleResume.bind(this);
        this.handleStreamClose = this.handleStreamClose.bind(this);
        this.downloadAudio = this.downloadAudio.bind(this);
    }

    handleGranted() {
        this.setState({ granted: true });
        console.log("Permission Granted!");
    }
    handleDenied(err) {
        this.setState({ rejectedReason: err.name });
        console.log("Permission Denied!", err);
    }
    handleStart(stream) {
        this.setState({
            recording: true,
        });

        console.log("Recording Started.");
    }
    handleStop(blob) {
        if (this.state.recording) {
            this.setState({
                recording: false,
            });


            console.log("Recording Stopped.");
            this.downloadAudio(blob, this.props.submitAnswer);
        }
    }
    handlePause() {
        this.setState({
            paused: true
        });
    }
    handleResume(stream) {
        this.setState({
            paused: false
        });
    }
    handleStreamClose() {
        this.setState({
            granted: false
        });
    }
    handleError(err) {
        //this.setState({ rejectedReason: err });
        console.log(err);
    }

    downloadAudio(blob, submitAnswer) {
        submitAnswer({
            answerType: "VOICE",
            answerValue: { blob }
        });
    }


    render() {
        const granted = this.state.granted;
        const rejectedReason = this.state.rejectedReason;
        const recording = this.state.recording;
        const paused = this.state.paused;

        return (
            <div className="flex flex-col w-full justify-center items-center" id="voice-question" ref="app">
                {this.props.isPlayer &&

                    <MediaCapturer
                        constraints={{ audio: true }}
                        mimeType="audio/webm"
                        timeSlice={10}
                        onGranted={this.handleGranted}
                        onDenied={this.handleDenied}
                        onStart={this.handleStart}
                        onStop={this.handleStop}
                        onPause={this.handlePause}
                        onResume={this.handleResume}
                        onError={this.handleError}
                        onStreamClosed={this.handleStreamClose}
                        render={({ request, start, stop, pause, resume }) => {

                            const handleButtonRecordEnd = () => {
                                setTimeout(() => {
                                    stop();    
                                }, 500);
                            }

                            const handleButtonRecordStart = () => {
                                start();
                            }

                            const activeCSSText = () => {
                                return this.state.recording ?
                                    "bg-red-600 -translate-y-1 scale-125"
                                    : "bg-purple-600 hover:bg-purple-500";
                            }

                            const promptText = () => {
                                return this.state.recording ?
                                    "Recording, click the button to submit"
                                    : "Press and hold the button to speak";
                            }


                            return (
                                <div className="flex flex-col h-40 w-64 w-full items-center justify-center select-none"
                                    // onMouseDown={handleButtonRecordStart}
                                    onMouseUp={handleButtonRecordEnd}

                                    onTouchStart={handleButtonRecordStart}
                                    onTouchEnd={handleButtonRecordEnd}
                                >
                                    <p>{promptText()}</p>

                                    <button

                                        id="ChatRoomMicBtn"
                                        // onTouchStart={start}
                                        // onTouchEnd={() => setTimeout(() => {
                                        //     stop()
                                        // }, 0)}
                                        onMouseDown={handleButtonRecordStart}
                                        // onMouseUp={handleButtonRecordEnd}
                                        className={`flex justify-center items-center p-0 w-20 h-20 rounded-full mouse shadow transition ease-in duration-200 focus:outline-none transform transition duration-300 ease-in-out select-none ${activeCSSText()}`}>
                                        <i className="fas fa-microphone fa-2x" style={{ color: "white" }}></i>
                                    </button>

                                    <div className="flex flex-col w-full items-center justify-center">
                                        {!granted && <button onClick={request}>Click to grant microphone permission</button>}
                                    </div>
                                </div>
                            )
                        }}
                    />
                }
            </div>
        );
    }
}

