
import React, { useState } from 'react';


const KweejIcon = ({ faClass, iconColor = "white", bgColor = "purple-200", onClick }) => {
    return (
        <button
            className={`mt-2 flex justify-center items-center p-0 w-8 h-8 select-none bg-${bgColor}`}
            onClick={onClick}
        >
            <i className={faClass} color={iconColor}></i>
        </button>

    )
}

export default KweejIcon;
