import { observable, action, decorate } from "mobx";

class AnnounceSplashState {
  showing = false;
  message = null;
  clickToDismiss = false;
  autoDismiss = false;
  timer = 5000;

  show() {
    this.showing = true;
  }

  hide() {
    this.showing = false;
  }

  setMessage(msg) {
    this.message = msg;
  }

  setClickToDismiss(ctd) {
    this.clickToDismiss = ctd;
  }

  init(msg, showing = false, autoDismiss = false, clickToDismiss = true, timer = 5000) {
    this.message = msg;
    this.clickToDismiss = clickToDismiss;
    this.autoDismiss = autoDismiss;
    this.showing = showing;
    this.timer = timer;
  }
}

decorate(AnnounceSplashState, {
  showing: observable,
  timer : observable, 
  message : observable,
  clickToDismiss : observable,
  autoDismiss : observable,
  show: action,
  hide: action,
  setMessage: action,
  setClickToDismiss: action,
  init : action
})

export const announceSplashState = new AnnounceSplashState();
