import React, { useEffect } from 'react';
import _ from 'lodash';
import QuestionSummary from './QuestionSummary.js';
import {CloseSquareTwoTone, CheckSquareTwoTone} from '@ant-design/icons';
import UIfx from 'uifx'

import correctAnswerAudio from '../../assets/sounds/CorrectAnswer.mp3'
import wrongAnswerAudio from '../../assets/sounds/WrongAnswer.mp3'
import ChessTacticsSolution from './QuestionDisplay/SolutionOptions/ChessTacticsSolution/ChessTacticsSolution.js';

const correctAnswerSound = new UIfx(correctAnswerAudio);
const wrongAnswerSound = new UIfx(wrongAnswerAudio);





export default function PlayerQuestionOver({answeredCorrect}) {

    useEffect(() => {
        if(answeredCorrect) {
            correctAnswerSound.play();
        } else {
            wrongAnswerSound.play();
        }
    }, [])

    return (
        <div>

            {
                answeredCorrect
                    ?
                    <div className='flex flex-col items-center justify-center p-4' >
                        <CheckSquareTwoTone style={{fontSize:"50px"}} twoToneColor="#52c41a" />
                    </div>
                    :
                    <div className='flex flex-col items-center justify-center p-4' >
                        <CloseSquareTwoTone style={{fontSize:"50px"}} twoToneColor="#eb2f96" />

                    </div>
            }

            <QuestionSummary />
        </div>
    )
}