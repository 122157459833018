import { action, observable, decorate, computed } from "mobx";
import { uniq, without } from "lodash-es";

import { questionState } from "./questionState";
import { livesState } from "./livesState";

class HangmanGuessState {
  correctGuess = [];
  guessStatusEventListeners = [];
  wrongGuess = [];
  submittedAnswer = false;

  guessStatus = null;

  isChecking = false;

  finalResult = "";

  addGuessedLetter(letter) {
    const answer = without(uniq(questionState.sentence), " ");

    this.isChecking = true;

    if (questionState.sentence.includes(letter)) {
      this.guessStatus = "correct";
      if (!this.correctGuess.includes(letter)) {
        this.correctGuess.push(letter);
      }

      if (this.correctGuess.length === answer.length) {
        setTimeout(() => {
          this.resetGuess();
          this.isChecking = false;
        }, 1000);

        this.finalResult = "correct"
        if (!this.submittedAnswer) {
          this.submittedAnswer = true;
          this.guessStatusEventListeners.forEach((callback) => callback(this.finalResult))
        };
        return "correctGuess";
      }
    } else {
      this.guessStatus = "wrong";
      if (!this.wrongGuess.includes(letter)) {
        this.wrongGuess.push(letter);

        if (livesState.lives === 1) {
          setTimeout(() => {
            this.correctGuess = [...answer];
          }, 250);

          setTimeout(() => {
            questionState.result = "wongGuess";
            this.isChecking = false;
            this.resetGuess();
          }, 1000);
          livesState.decreaseLives();
          this.finalResult = "incorrect";
          if (!this.submittedAnswer) {
            this.submittedAnswer = true;
            this.guessStatusEventListeners.forEach((callback) => callback(this.finalResult));
          }
          return "wongGuess";
        }
        if (livesState.lives > 0) livesState.decreaseLives();
      }
    }

    this.isChecking = false;

    return "";
  }

  resetGuess() {
    this.correctGuess = [];
    this.wrongGuess = [];
    this.guessStatus = null;
    this.finalResult = "";
    this.submittedAnswer = false;
    this.guessStatusEventListeners = [];
    questionState.reset();
  }

  // correctGuessString() {
  //   return this.correctGuess.join(" ");
  // }

  get correctGuessString() {
    return this.correctGuess.join(" ").toUpperCase();
  }

  get wrongGuessString() {
    return this.wrongGuess.join(" ").toUpperCase();
  }

  addStatusEventListener(callback) {
    if (this.guessStatusEventListeners.length == 0) this.guessStatusEventListeners.push(callback);
  }
}

decorate(HangmanGuessState, {
  correctGuess: observable,
  wrongGuess: observable,
  guessStatus: observable,
  isChecking: observable,
  finalResult: observable,
  addGuessedLetter: action,
  resetGuess: action,
  correctGuessString: computed,
  wrongGuessString: computed,
  addStatusEventListener: action
})

export const hangmanGuessState = new HangmanGuessState();
