import React, { Component } from 'react';
import { Route, Switch, BrowserRouter } from 'react-router-dom';
import HomePage from './screens/HomePage/HomePage';
import GamePageMain from './screens/GamePage/GamePageMain'
import HostAdminMain from './screens/HostAdminPage/HostAdminMain';
import Fullscreen from "react-full-screen";
import AboutPage from './screens/HomePage/AboutPage';
import QuestionEditor from './screens/QuestionEditorPage/QuestionEditor';
import { Auth0Provider } from "@auth0/auth0-react";
import ZoomPageMain from './ZoomPageMain';


class App extends Component {

  constructor(props) {
    super();

    this.state = { isFull: false };
  }

  goFull = () => {
    if (window.innerWidth < 700) this.setState({ isFull: false });
  }

  componentDidMount() {
    console.log('process.env', process.env);
    console.log('window.location :>> ', window.location);
    console.log('window.location.origin :>> ', window.location.origin);
  }

  render() {

    const api_regex = /^\/api\/.*/

    console.log('api_regex :>> ', api_regex);
    console.log('window.location.pathname :>> ', window.location.pathname);

    // if (api_regex.test(window.location.pathname)) {
    //   console.log("Regex tested positive");
      
    //   return <div /> // must return at least an empty div
    // } else {

      return (
        <Auth0Provider
          domain="quizappkt.auth0.com"
          clientId="HwhjqgxHJiMjr7WTk5llC5PXjqeA6TY6"
          redirectUri={window.location.origin + "#/host"}
        //{process.env.NODE_ENV === "production" ? "https://www.kweej.com#/host" : "http://localhost:3000#/host"}
        >
          <Fullscreen
            enabled={this.state.isFull}
            onChange={isFull => this.setState({ isFull })}
          >
            <Switch>
              <Route
                exact path='/'
                // component={HomePage} 
                render={(routeProps) => (
                  <HomePage {...routeProps} goFull={this.goFull} />
                )}
              />
              <Route exact path='/about' component={AboutPage} />
              <Route exact path='/game' component={GamePageMain} />
              <Route exact path='/zoom' component={ZoomPageMain} />
              <Route path='/host' exact component={HostAdminMain} />
              <Route path='/host/questioneditor' exact component={QuestionEditor} />

            </Switch>
          </Fullscreen>
        </Auth0Provider>
      );
    }
  // }
}

export default App;