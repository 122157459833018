import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { announceSplashState } from './AnnounceSplashState'
import Anime from "react-anime";
import StateMonitor from './StateMonitor'
import { useSelector } from 'react-redux';



const AnnounceSplash = ({handleSplashDismissClick}) => {

  const resultTextClass = "text-6xl md:text-8xl inline-block";

  const isPlayer = useSelector(state => state.isPlayer);
  const isHost = useSelector(state => state.isHost);


  useEffect(() => {
    if (announceSplashState.showing && announceSplashState.autoDismiss) setTimeout(() => {
      announceSplashState.hide();
    }, announceSplashState.timer);
  })

  return (
    <div class="flex w-full relative flex-1 h-screen overflow-auto">
      {/* <StateMonitor /> */}
      {announceSplashState.showing && (
        <div
          class="flex flex-1 opacity-90 z-10 h-full w-full bg-white items-center justify-center"
          onClick={() => announceSplashState.clickToDismiss ? announceSplashState.hide() : null}
        >
          <div class="flex flex-col px-5 w-full items-center justify-start">
            <div class="flex items-center justify-center flex-col md:flex-row">
              <Anime
                easing="easeOutElastic"
                duration={1000}
                direction="normal"
                translateY={["-100vh", "0"]}
              >
                <span id="splashTitle1" class="text-3xl md:text-4xl text-center inline-block">
                  {announceSplashState.message.mainTitle1}
                </span>
              </Anime>
              <div class="w-5"></div>
              <Anime
                easing="easeOutElastic"
                duration={1000}
                direction="normal"
                translateY={["100vh", "0"]}
              >
                <span
                  id="splashTitle2"
                  class="text-3xl md:text-4xl text-center inline-block text-blue-500">
                  {announceSplashState.message.mainTitle2}
                </span>
              </Anime>
            </div>
            {announceSplashState.message.messageParas && announceSplashState.message.messageParas.map((para) => (
              <div id="splashPara" class="flex flex-col py-5 w-full items-center" style={{ whiteSpace: "pre-line" }}>
                <span id="paraTitle" class="text-xl font-bold">
                  {para.title}
                </span>
                <span id="contentText" class="text-lg">
                  {para.text}
                </span>
              </div>
            ))}

            {(!announceSplashState.autoDismiss && isHost) &&
              <div id="dismiss">
                <button className="btn btn-primary" onClick={handleSplashDismissClick}>
                  Dismiss
              </button>
              </div>
            }

          </div>
        </div>

      )}


    </div>

  );
}

export default observer(AnnounceSplash);