import React from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { Flipper, Flipped } from "react-flip-toolkit";
import { observer } from 'mobx-react-lite';
import CountUp from 'react-countup';
// import {globalState} from '../../utils/mobx/GlobalState';


const Leaderboard = () => {

    const gameState = useSelector(state => state.gameState);
    let sortedLeaderboard = _.orderBy(gameState.playersList, ['active', 'score', 'nickname'], ['desc', 'desc', 'asc']);

    return (

        <div className="flex flex-col overflow-x-hidden overflow-y-auto border-gray-300 overflow-y-auto bg-teal-300">
            <Flipper flipKey={JSON.stringify(sortedLeaderboard)} spring='wobbly' >

                {sortedLeaderboard.map((player, index) => (
                    <Flipped flipId={player.nickname} key={player.nickname} stagger >

                        <div className="flex flex-no-wrap justify-between bg-white rounded border shadow my-1 py-2">
                            <div className="flex px-3 items-center">
                                <span className="text-3xl">#{index + 1}</span>
                                <img className={"w-12 h-12 flex flex-shrink-0 ml-2 mr-2 ".concat(gameState.currentQuestion.isQuestionOver && !gameState.isGameOver && (player.score > player.prevScore)? "animate-bounce" : "")} src={player.avatar} alt="avatar" />
                                <p className={player.active? "font-bold" : "text-gray-400"}>{player.nickname}{!player.active && " (inactive)"}</p>
                            </div>
                            <div className="flex text-2xl text-orange-600 font-medium items-center bg-white px-3">
                                <CountUp start={player.prevScore} end={player.score} duration={1.5} />
                            </div>
                        </div>
                    </Flipped>
                ))}
            </Flipper>
        </div>

    )
}

export default observer(Leaderboard);