import React, { useRef, useEffect } from 'react';

export default function TextQuestion(props) {
    const textInputEl = useRef(null);
   
    const handleSubmit = (submitAnswer) => {
        if(textInputEl.current.value === "") {
            alert("Cant submit empty answer");
        } else {
            submitAnswer({
                answerType: "TEXT",
                answerValue: textInputEl.current.value
            });
            textInputEl.current.value = "";
        }

    }

    const handleInput = (event, submitAnswer) => {
        if (event.key === 'Enter') {
            handleSubmit(submitAnswer);
        }
    }

    useEffect(() => {
        //if(textInputEl) textInputEl.focus();
    })
    return (
        <div className="flex flex-col items-center justify-center" id='code-question' >
            {props.isPlayer &&
                <div className="flex flex-row items-center justify-center">

                    <input className="bg-gray-100 appearance-none border-2 border-gray-500 rounded w-full mx-1 my-1 mb-3 py-2 px-4 text-lg text-gray-700 text-center leading-tight focus:outline-none focus:bg-white focus:border-purple-500" type="text"
                        placeholder="Enter the answer text here"
                        ref={textInputEl}
                        onKeyPress={(e) => handleInput(e, props.submitAnswer)}
                    />

                    <button className="shadow bg-purple-600 hover:bg-purple-500 focus:shadow-outline focus:outline-none text-white mx-1 my-1 mb-3 py-2 px-4 rounded"
                        type="button"
                        onClick={() => { if (props.isPlayer) handleSubmit(props.submitAnswer) }} >
                        Submit
                    </button>
                </div>
            }
        </div>
    )
}