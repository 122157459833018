import React from 'react';
import PropTypes from 'prop-types';
import { Draggable } from 'react-beautiful-dnd';
import "./Letter.css"



const Letter = ({ dragActive, clicked, letter, index }) => (
    <Draggable
        draggableId={letter.id}
        isDragDisabled={!dragActive}
        disableInteractiveElementBlocking
        index={index}
        key={letter.id}
    >
        {(provided) => (
            <li
                /*eslint-disable react/jsx-props-no-spreading*/
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                ref={provided.innerRef}
                className="Letter"
            >
                <button
                    type="button"
                    tabIndex="-1"
                    className="LetterButton"
                    disabled={!letter.active}
                    onClick={() => clicked(letter.id)}
                >
                    {letter.value}
                </button>
            </li>
        )}
    </Draggable>
);

// Letter.propTypes = {
//     dragActive: PropTypes.bool.isRequired,
//     clicked: PropTypes.func,
//     letter: PropTypes.object.isRequired,
//     index: PropTypes.number.isRequired,
// };

// Letter.defaultProps = {
//     clicked: () => {},
// };

export { Letter };
