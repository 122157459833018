import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { handleNickname, selectedPin, setHost, setPlayer } from '../../utils/ducks/Reducer';
import AvatarPicker from './AvatarPicker';
import { observer } from 'mobx-react-lite';
import kweejLogo from '../../assets/KweejLogo.svg'
import { Redirect } from 'react-router-dom';
import queryString from 'query-string';
import { useAuth0 } from "@auth0/auth0-react";
import getSocket, {playerJoin} from '../../utils/socket';
// import { globalState } from '../../utils/mobx/GlobalState'
import { updateGameState } from '../../utils/ducks/Reducer';
import BlockUi from 'react-block-ui';


const HomePage = (props) => {

    const { loginWithRedirect } = useAuth0();

    const [pin, setPin] = useState(process.env.NODE_ENV === 'production' ? '' : '1234');
    const [nickname, setNickname] = useState(process.env.NODE_ENV === 'production' ? '' : 'Player'.concat(Math.round(Math.random() * 1000 + 1)));
    const dispatch = useDispatch();
    const [redirect, setRedirect] = useState(false);
    const [playerLoginBtnBlocked, setPlayerLoginBtnBlocked] = useState(false);
    const store = useStore();

    const avatar = useSelector(state => state.avatar)

    useEffect(() => {
        //let propPin = props.location.query.pin;
        let params = queryString.parse(props.location.search);

        if (params.pin) setPin(params.pin);
    });


    const handleInput = (e) => {
        setPin(e.target.value);

    }

    const handleNicknameInput = (e) => {
        setNickname(e.target.value);
    }

    const handlePlayerLoginBtnClick = () => {

        if (nickname === "" || pin === "") {
            alert("Enter both pin and nickname");
        } else {

            //Player and host who is playing register themselves as a player with the server
            setPlayerLoginBtnBlocked(true);
            // let socket = getSocket();

            // socket.on('room-joined', ({ player, gameState }) => {
            //     dispatch(updateGameState(gameState));
            // });

            playerJoin(pin, nickname, avatar).
                then((msg) => {
                    setPlayerLoginBtnBlocked(false);
                    props.goFull();
                    setRedirect(true);
                }).
                catch((msg) => {
                    setPlayerLoginBtnBlocked(false);
                    alert(msg);
                })

            // socket.emit('player-joined', {
            //     pin, nickname, "avatar": globalState.avatar, uuid
            // }, (success) => {
            //     if (!success) {
            //         alert("Game not started yet by host. Try again after some time");
            //         setPlayerLoginBtnBlocked(false);
            //         //window.location.assign("/")
            //     } else {
            //         setPlayerLoginBtnBlocked(false);

            //         sessionStorage.setItem(pin, uuid);
            //         dispatch(handleNickname(nickname));
            //         dispatch(selectedPin(pin));
            //         dispatch(setHost(false));
            //         dispatch(setPlayer(true));
            //         props.goFull();
            //         setRedirect(true);

            //     }
            // });


        }

    }

    if (redirect) {
        return <Redirect to='/game' />
    }


    return (
        <div className="flex h-screen flex-col items-center justify-center">

            <div className="w-3/4 max-w-xs">
                <div className="flex-shrink-0 mx-10 mt-2 mb-6">
                    <img src={kweejLogo} alt="Kweej logo" />
                </div>
                <form className="w-full">
                    <span className="text-2xl">Steady to join a game?</span>
                    <div className="my-3">
                        <input id="input-pin" value={pin} onChange={handleInput} className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-lg text-gray-700 text-center leading-tight focus:outline-none focus:bg-white focus:border-purple-500" type="text" placeholder="Enter PIN" autoComplete="off" />
                    </div>

                    <div className="my-3">
                        <input autoFocus id="input-nickname" value={nickname} onChange={handleNicknameInput} className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-lg text-gray-700 text-center leading-tight focus:outline-none focus:bg-white focus:border-purple-500" type="text" placeholder="Nickname" />
                    </div>

                    <div className="flex flex-col items-center justify-center">
                        <span className="w-full text-center my-3">Choose avatar</span>

                        <AvatarPicker />
                    </div>

                    {/* <Link to='/game'> */}

                    <div className="my-3">
                        <BlockUi blocking={playerLoginBtnBlocked}>
                            <button id="btn-player-login"
                                className="w-full shadow bg-purple-600 hover:bg-purple-500 focus:shadow-outline focus:outline-none text-xl text-white font-bold py-2 px-4 rounded"
                                type="button"
                                onClick={handlePlayerLoginBtnClick}
                            >
                                Let's go
                        </button>

                        </BlockUi>

                    </div>
                    {/* </Link> */}

                </form>


                <div className="flex justify-center mt-4">
                    <button onClick={() => loginWithRedirect()} className="text-xl text-purple-600 hover:text-purple-400">
                        I want to host a game
                    </button>
                </div>



            </div>
            <div className="flex text-xs absolute bottom-0 right-0">
                <Link to="/about">About</Link>
            </div>
        </div>
    )
}

export default observer(HomePage);