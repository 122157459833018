import React, { useState } from 'react';

import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import KweejIcon from '../KweejIcon';
//import { useWindowSize } from '../../utils/util'

const ResponsiveSidePanel = ({ children, anchor = "left", swipeable = false }) => {

    const [visible, setVisible] = useState(false);

    return (
        <div>
            <div className="hidden md:block">
                {children}
            </div>
            <div className="block visible md:hidden">
                <KweejIcon faClass="fas fa-filter"
                    onClick={() => setVisible(true)} />

                <SwipeableDrawer
                    disableSwipeToOpen={!swipeable}
                    disableDiscovery
                    hysteresis={0.1}
                    anchor={anchor}
                    minFlingVelocity={250}
                    swipeAreaWidth={10}
                    open={visible}
                    onClose={() => setVisible(false)}
                    onOpen={() => setVisible(true)}
                >
                    <div className="flex flex-col h-full w-64">
                        <div className="h-10 justify-left">
                            <KweejIcon faClass="far fa-times-circle fa-lg"
                                iconColor="gray"
                                bgColor="white"
                                onClick={() => setVisible(false)} />
                        </div>
                        {children}

                    </div>

                </SwipeableDrawer>
            </div>
        </div>
    )


}

export default ResponsiveSidePanel;