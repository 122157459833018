import React from 'react'
// import { Select } from 'antd'
// import 'antd/lib/select/style/index.css'

import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';


// const { Option } = Select;

const AnswerFormatSelector = ({ selectedAnswerFormat, answerFormats, handleAnswerFormatChange }) => {
    return (
        <div className="flex items-center justify-end">
            <p className="text-xs m-1 justify-end">Answer format</p>


            <Select
                value={selectedAnswerFormat}
                onChange={handleAnswerFormatChange}
                displayEmpty
            >
                {answerFormats.map((answerFormat) => {
                    return <MenuItem value={answerFormat.type}>{answerFormat.description}</MenuItem>
                })}       
            </Select>


        </div>
    )
}

export default AnswerFormatSelector
