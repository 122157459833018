import React from 'react';


function handleAnswer(submitAnswer, ansValue) {
    submitAnswer({
        answerType: "TEXT",
        answerValue: ansValue
    });
}

export default function MCQ2Question({submitAnswer, questionDetails, isPlayer}) {
    return (
        <div className="flex flex-row flex-wrap">

            <button className="w-1/2 focus:outline-none" onClick={() => { if (isPlayer) handleAnswer(submitAnswer, questionDetails.choice1) }}>
                <p className="text-center md:text-lg lg:text-xl bg-orange-200 shadow hover:bg-orange-300 rounded px-2 py-2 m-1 h-16">{questionDetails.choice1}</p>
            </button>  
        
            <button className="w-1/2 focus:outline-none" onClick={() => { if (isPlayer) handleAnswer(submitAnswer, questionDetails.choice2) }}>
                <p className="text-center md:text-lg lg:text-xl bg-blue-200 shadow hover:bg-blue-300 rounded px-2 py-2 m-1 h-16">{questionDetails.choice2}</p>
            </button>

        </div>
    )
}