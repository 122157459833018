import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectedQuiz, setHost, handleNickname, selectedPin, setPlayer } from '../../utils/ducks/Reducer';
import { Checkbox } from 'antd';
import 'antd/lib/checkbox/style/index.css'

// import { observer } from 'mobx-react-lite';
// import { globalState } from '../../utils/mobx/GlobalState';

import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';

import getSocket, { playerJoin } from "../../utils/socket.js";
import QuizCard from './QuizCard';
import { getServerDomain } from '../../utils/util';
import CheckAuthentication from '../../utils/components/CheckAuthentication';
import { KweejFilterGroup } from '../../utils/components/KweejFilter/KweejFilter';
import ResponsiveSidePanel from '../../utils/components/ResponsiveSidePanel';
let socket = null;



const HostAdminMain = (props) => {


    const [quizzes, setQuizzes] = useState([]);
    const [redirect, setRedirect] = useState(false);
    const [blocked, setBlocked] = useState(false);

    const [isHostPlayer, setHostPlayer] = useState(false);
    const [pagination, setPagination] = useState({ limit: 10, offset: 0 })

    const [autoAdvanceQuestions, setAutoAdvanceQuestions] = useState(false);

    const avatar = useSelector(state => state.avatar)

    const dispatch = useDispatch();


    const [filterOptions, setFilterOptions] = useState({
        category: {
            title: "Quiz category",
            options: []
        }
    })


    const fetchQuizzes = (filters, sort) => {
        setBlocked(true);
        axios.post(getServerDomain() + `/api/getQuizzes`, {
            filters: filters,
            pagination: pagination,
            sort: sort
        }).then(res => {
            setBlocked(false);
            setQuizzes(res.data);
            setPagination({ ...pagination, total: res.data.length });
            dispatch(selectedQuiz(res.data));
        })
    }

    const getQuizCategories = async () => {

        try {
            let response = await axios.get(getServerDomain() + `/api/getQuizCategories`);
            console.log('response :>> ', response);
            let newFilterOptions = { ...filterOptions };
            newFilterOptions.category.options = response.data;
            setFilterOptions(newFilterOptions);

        } catch (err) {
            console.log("Could not load quiz categories", err);
        }


    }

    useEffect(() => {


        socket = getSocket();

        getQuizCategories().
            then(fetchQuizzes());

        dispatch(setHost(true));
        dispatch(setPlayer(isHostPlayer));
        dispatch(handleNickname("Host"));

    }, []);


    const handlePlayButtonClick = (quizID) => {

        setBlocked(true);

        dispatch(setPlayer(isHostPlayer));


        socket.emit('host-join', {
            "isHostPlayer": isHostPlayer,
            "autoAdvanceQuestions": autoAdvanceQuestions,
            "quizID": quizID
        },
            (pin) => {

                if (isHostPlayer) {
                    playerJoin(pin, "Host", avatar)
                        .then((msg) => {
                        })
                        .catch((msg) => {
                            console.log("Couldnt join as player", msg)
                        })
                }

                dispatch(selectedPin(pin));
                setBlocked(false);
                setRedirect(true);


            }
        );

    }

    const onHostPlayerCheckboxChange = (e) => {
        setHostPlayer(e.target.checked);
    }

    const onAutoAdvanceQuestionsCheckboxChange = (e) => {
        setAutoAdvanceQuestions(e.target.checked);
    }

    if (redirect) {
        return <Redirect to='/game' />
    }

    let mappedQuizzes = quizzes.map((quiz, index) => {
        return (
            <div className="flex m-5">

                <QuizCard
                    quizPoster={quiz.quizDetails.quizDisplayAsset}
                    quizTitle={quiz.quizName}
                    quizDescription={quiz.info}
                    tags={[quiz.category]}
                    creatorName={null}
                    handleQuizPlay={() => handlePlayButtonClick(quiz.quizID)}

                />

            </div>

        )
    })

    const handleApplyFiltersClick = (filters) => {
        fetchQuizzes(filters);
    }



    return (

        <CheckAuthentication>

            <div className="h-screen flex">


                <ResponsiveSidePanel swipeable>
                    <div id="filter-panel" className="flex flex-col">

                        <div className="flex flex-col p-2">
                            <p className="text-xl">Game options</p>
                            <Checkbox checked={isHostPlayer} onChange={onHostPlayerCheckboxChange}>Host playing</Checkbox>
                            <Checkbox checked={autoAdvanceQuestions} onChange={onAutoAdvanceQuestionsCheckboxChange} style={{ margin: 0 }}>Auto question advance</Checkbox>
                        </div>

                        <KweejFilterGroup filterOptions={filterOptions} onSubmit={handleApplyFiltersClick} />

                        <div className="flex absolute bottom-0 left-0 p-4" >
                            <Link to='/host/questioneditor' >
                                <button className='btn btn-primary'>
                                    <span className="icon is-large">
                                        <i className="icon fas fa-plus">
                                        </i>
                                    </span>
                                    <span>Question Editor</span>
                                </button>
                            </Link>
                        </div>
                    </div>

                </ResponsiveSidePanel>




                <div id="results-panel" className="flex overflow-hidden flex-1">
                    <div class="flex-1 flex-row w-9/12 overflow-y-scroll">


                        <BlockUi tag="div" blocking={blocked}>

                            <div className="flex flex-wrap">

                                {mappedQuizzes}
                                <br />
                            </div>
                        </BlockUi>

                    </div>
                </div>



            </div>
        </CheckAuthentication>
    )
}

export default HostAdminMain;