import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux'
import 'antd/lib/table/style/index.css'
import 'antd/lib/button/style/index.css'

import { ConfigProvider, Table } from "antd";

import _ from 'lodash';
import io from 'socket.io-client';

import getSocket from '../../utils/socket'

let socket = null;

const customizeRenderEmpty = () => (
    <div style={{ textAlign: 'center' }}>
        <span>No one answered yet</span>
    </div>
);

const columns = [
    {
        title: 'Player',
        dataIndex: 'nickname',
    },
    {
        title: 'Answer',
        dataIndex: 'answerGiven',
        render: (answer) => {
            if (answer.answerType === "VOICE") {
                let blob = answer.answerValue.blob;
                let blobURL = URL.createObjectURL(new Blob([blob]));
                return <audio
                    style={{ "width": "200px" }}
                    controls="controls"
                    src={blobURL}
                    type="audio/mp3" />
            } else {
                return <span> {answer.answerValue} </span>
            }
        }
    }
];


export default function WinnerPicker({ winners }) {

    const [selectedItems, setSelectedItems] = useState([]);

    const pin = useSelector(state => state.selectedPin);
    const isHost = useSelector(state => state.selectedPin);
    const isPlayer = useSelector(state => state.selectedPin);

    const gameState = useSelector(state => state.gameState);


    useEffect(() => {
        socket = getSocket();
    })

    const submitWinners = (winners) => {
        socket.emit('host-winner-info', { winners, pin });
    }

    return (
        <div className="flex flex-col items-center justify-center mt-2">
             <p className="text-sm my-2">Host Hint : {gameState.currentQuestion.questionDetails.hostHint}</p>

            <p>Select all correct answers and press submit when done</p>


            <ConfigProvider renderEmpty={customizeRenderEmpty}>
                <Table
                    size = "small"
                    rowSelection={{
                        onChange: (selectedRowKeys, selectedRows) => {
                            setSelectedItems(selectedRows);
                        },
                        getCheckboxProps: record => ({
                            nickname: record.nickname
                        })
                    }}
                    columns={columns}
                    dataSource={winners}
                    pagination={{ hideOnSinglePage: true }}
                    rowKey="rowNum"
                />
            </ConfigProvider>
            {(true || (gameState.currentQuestion.playersWhoHaveAnswered.length === gameState.playersList.length) ||
                (isHost && isPlayer && gameState.currentQuestion.playersWhoHaveAnswered.length === gameState.playersList.length - 1)) &&
                <button className="w-full shadow bg-purple-600 hover:bg-purple-500 focus:shadow-outline focus:outline-none text-xl text-white font-bold py-2 px-4 rounded"
                    type="button"
                    onClick={() => submitWinners(selectedItems)} >
                    Submit winners
                </button>
            }
        </div>

    )
}