import React from 'react'
// import { Select } from 'antd'
// import 'antd/lib/select/style/index.css'

// const { Option } = Select;
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';




const QuestionTypeSelector = ({ selectedQuestionType, questionTypes, handleQuestionTypeChange }) => {
    return (
        <div className="flex items-center justify-end">
            
            <p className="text-xs m-1 justify-end">Question Type</p>


            <Select
                value={selectedQuestionType}
                onChange={handleQuestionTypeChange}
                displayEmpty
            >
                {questionTypes.map((questionType) => {
                    return <MenuItem value={questionType.type}>{questionType.description}</MenuItem>
                })}       
            </Select>

        </div>
    )
}

export default QuestionTypeSelector
