import store from './ducks/Store';
import {useState, useEffect} from 'react';

export function getGameStatus() {
    let status = "GAME_NOT_STARTED"

    const { gameState, isPlayer, isHost, answerSubmitted } = store.getState();

    if (!gameState.isGameStarted) {
        if (isHost) status = "GAME_NOT_STARTED_HOST"
        else status = "GAME_NOT_STARTED_PLAYER"
    }
    else { //Game has started

        if (!gameState.currentQuestion.isQuestionOver) {

            if (isPlayer && !(isHost && gameState.currentQuestion.isHostDetermined)) { //Host cant play a host determined question 

                if (!answerSubmitted) status = "PLAYER_ANSWERING_QUESTION"
                else status = "PLAYER_WAITING_FOR_OTHERS_TO_ANSWER"

            } else { //is only host
                if (gameState.currentQuestion.isHostDetermined) status = "HOST_AWAITING_ANSWERS_HOST_DETERMINED_QUESTION"
                else status = "HOST_AWAITING_ANSWERS_FOR_NORMAL_QUESTION"
            }

        } else { //Question is over 

            if (gameState.isGameOver) {
                if (isHost) status = "HOST_GAME_OVER"
                else status = "PLAYER_GAME_OVER"

            } else { //GAme not over only question over
                if (isHost) status = "HOST_QUESTION_OVER"
                else status = "PLAYER_QUESTION_OVER"
            }
        }
    }
    return status;
}

export function getSplashScreenParams() {

    const { gameState, isPlayer, isHost, answerSubmitted } = store.getState();


}

export function getHashInt(str, upperBound) {

    let charCount = 0;

    for (let i = 0; i < str.length; i++) {
        charCount += str.charCodeAt(i);
    }

    return charCount % upperBound;

}

export function getHashColor(str) {
    const arrayColors = ['red-600', 'orange-600', 'yellow-800', 'green-600', 'blue-700', 'purple-500', 'teal-600', 'indigo-500', 'pink-700', 'gray-600']

    return arrayColors[getHashInt(str, arrayColors.length)];
}

export function getServerDomain() {

    let clientDomain = window.location.origin;

    if(clientDomain.includes('localhost:3000')) return "http://localhost:3030" 
    else if (clientDomain.includes('kweej.com')) return clientDomain 
    else if (clientDomain.includes('kweej.vercel.app')) return "https://www.kweej.com" 
    else if (clientDomain.includes('kinto.io')) return clientDomain+":3030" 
    else return "https://www.kweej.com"    
}

// Hook
export function useWindowSize() {
    const isClient = typeof window === 'object';

    function getSize() {
        return {
            width: isClient ? window.innerWidth : undefined,
            height: isClient ? window.innerHeight : undefined
        };
    }

    const [windowSize, setWindowSize] = useState(getSize);

    useEffect(() => {
        if (!isClient) {
            return false;
        }

        function handleResize() {
            setWindowSize(getSize());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []); // Empty array ensures that effect is only run on mount and unmount

    return windowSize;
}
