import React, { useState, useEffect } from 'react';
import SimpleImageSlider from "react-simple-image-slider";
// import { globalState } from "../../utils/mobx/GlobalState"
import { useDispatch } from 'react-redux';
import AliceCarousel from 'react-alice-carousel'
import 'react-alice-carousel/lib/alice-carousel.css'

import {setAvatar} from '../../utils/ducks/Reducer'
import avatars from '../../assets/avatars/Avatars'
// import { observer } from "mobx-react-lite";

function avatarTransform(avatars) {
    let returnArr = [];
    avatars.forEach(avatar => {
        returnArr.push({ url: avatar })
    })
    return returnArr;
}

const AvatarPicker = (props) => {
    const dispatch = useDispatch();


    useEffect(() => {
        dispatch(setAvatar(avatars[0]));
    }, [])

    const handleClick = (idx, length) => {
        dispatch(setAvatar(avatars[idx - 1]));
    };

    return (
        <SimpleImageSlider
            width={170}
            height={170}
            bgColor="white"
            slideDuration={0.1}
            onCompleteSlide={handleClick}
            images={avatarTransform(avatars)}
        />

        // <div className="items-center justify-center w-40">
        // <AliceCarousel      
        //     items={avatarTransform(avatars).map((i)=> <img src={i.url} />)}
        //     onSlideChanged={handleClick}
        //     mouseTrackingEnabled={true}

        // />
        // </div>

        
    );
}

export default AvatarPicker;
