import React, { useState, useEffect, useRef } from "react";
import { chessGameState } from "./state/chessGameState";
import Chessground from "react-chessground"
import "react-chessground/dist/styles/chessground.css"
import Chessboard from 'chessboardjsx';
import ChessgroundWrapper from '../../../../../utils/components/ChessgroundWrapper'



const ChessTacticsQuestion = ({ startPosition, answerMoves, submitAnswer, isPlayer }) => {

  const [position, setPosition] = useState(startPosition);
  const [currentMove, setCurrentMove] = useState(0);
  const [lastMove, setLastMove] = useState(null);

  let chessgroundEl = useRef(null);


  const makeOpponentMove = (move) => {

    return chessGameState.game.move(move);

  }


  const makeMove = (move) => {

    chessGameState.game.move(move);

  }

  useEffect(() => {
    chessGameState.init(startPosition)

    console.log("Making move : ", answerMoves[currentMove]);
    let move = makeOpponentMove(answerMoves[currentMove]);
    console.log('new fen after move :>> ', chessGameState.game.fen());
    setTimeout(() => {
      setPosition(chessGameState.game.fen());
      setLastMove([move.from, move.to]);

    }, 100)
    setCurrentMove(currentMove + 1);


  }, []);

  // const promotion = (e) => {
  //   const { chess } = this
  //   const from = this.pendingMove[0]
  //   const to = this.pendingMove[1]
  //   chess.move({ from, to, promotion: e })
  //   this.setState({
  //     fen: chess.fen(),
  //     lastMove: [from, to],
  //     selectVisible: false
  //   })
  //   setTimeout(this.randomMove, 500)
  // }

  const turnColor = () => {
    return chessGameState.game.turn() === "w" ? "white" : "black"
  }

  const calcMovable = () => {
    const dests = {}
    chessGameState.game.SQUARES.forEach(s => {
      const ms = chessGameState.game.moves({ square: s, verbose: true })
      if (ms.length) dests[s] = ms.map(m => m.to)
    })

    console.log('dests :>> ', dests);
    return {
      free: false,
      dests,
      showDests: true,
      color: "black"
    }
  }

  const onDrop = ({sourceSquare, targetSquare}) => { //code from chessboardjsx
    // debugger;

    console.log('currentMove :>> ', currentMove);
    console.log('chessGameState.game.fen() :>> ', chessGameState.game.fen());
    // console.log('moveObj :>> ', moveObj);
    let move = chessGameState.game.move({
      from: sourceSquare,
      to: targetSquare,
      promotion: 'q'
    });

    console.log('move :>> ', move);

    if (move === null) return;   // illegal move

    //legal move
    console.log('setting position :>> ', chessGameState.game.fen());
    setPosition(chessGameState.game.fen());
    setLastMove([sourceSquare, targetSquare]);
    console.log('chessGameState.game.fen()', chessGameState.game.fen())
    let temp = currentMove + 1;

    //check if move is correct one
    console.log("Move made :>> ", move.san);
    let correctMove = answerMoves[currentMove];
    console.log('correctMove :>> ', correctMove);

    if (!(move.san == correctMove)) {

      submitAnswer({
        answerType: "TEXT",
        answerValue: "incorrect"
      });
      return;
    } else {
      if (temp === (answerMoves.length)) {
        submitAnswer({
          answerType: "TEXT",
          answerValue: answerMoves.join(" ")
        });
        return;
      }
    }

    //Opponent move
    console.log("Making opponent move", answerMoves[temp]);
    console.log('currentMove', temp)
    makeOpponentMove(answerMoves[temp]);
    setPosition(chessGameState.game.fen());
    setCurrentMove(temp + 1);

  };


  return (


    <div className="flex flex-col w-full justify-center items-center">


      <Chessboard position={position}
          width="350"
          onDrop={isPlayer? onDrop : true}
          orientation={startPosition.includes("w") ? "black" : "white"}
        />



      {/* <ChessgroundWrapper
        width="350px"
        height="350px"
        turnColor={turnColor()}
        movable={calcMovable()}
        lastMove={lastMove}
        fen={position}
        onMove={onDrop}
        orientation={startPosition.includes("w") ? "black" : "white"}
        highlight={{ lastMove: true, check: true, dragOver: true }}
      /> */}

      <div className="h-8">
        position {position} <br />
        turnColor {turnColor()} <br />
        lastMove {lastMove}
      </div>

    </div>
  );
};

export default ChessTacticsQuestion;
