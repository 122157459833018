import React from 'react';
import ReactDOM from 'react-dom';
import './tailwind.generated.css';
import App from './App';
import register, { unregister } from './registerServiceWorker';
import { HashRouter } from 'react-router-dom';
import HttpsRedirect from 'react-https-redirect';
// import * as Sentry from '@sentry/react';
// import { Integrations } from '@sentry/tracing';
import { Provider } from 'react-redux';
import Store from './utils/ducks/Store';
import QuizComposer from './screens/QuizComposerPage/QuizComposer';
import ChessTacticsQuestion from './screens/GamePage/QuestionDisplay/AnswerOptions/ChessTacticsQuestion/ChessTacticsQuestion';
import ChessTacticsSolution from './screens/GamePage/QuestionDisplay/SolutionOptions/ChessTacticsSolution/ChessTacticsSolution';

import Chess from 'chess.js';
import ResponsiveSidePanel from './utils/components/ResponsiveSidePanel';
// import KweejFilterTester from './utils/components/KweejFilter/KweejFilter';

// Sentry.init({
//     dsn: "https://77ddc393055241eab68809f74b06c49e@o444368.ingest.sentry.io/5419384",
//     integrations: [
//       new Integrations.BrowserTracing(),
//     ],
//     tracesSampleRate: 1.0,
//   });
  

ReactDOM.render(
    <Provider store={Store}>
        <HashRouter>
            {process.env.NODE_ENV === 'production' ?
                <HttpsRedirect>
                    <App />
                </HttpsRedirect>
                :
                <App />
                // <ResponsiveSidePanel>
                //     <div className="bg-purple-100">
                //         Hello
                //     </div>
                // </ResponsiveSidePanel>
                // <QuizComposer />
                // <KweejFilterTester />
                // <ChessTacticsSolution
                //     startPosition={"5r2/5ppk/1Q2p1p1/4P3/p1r5/5qP1/PP3P1P/2RR2K1 w KQkq - 0 1"} 
                //     answerMoves = {[
                //         "Rxc4",
                //         "Qxd1+",
                //         "Kg2",
                //         "Qd5+",
                //         "*"
                //       ]} 
                // />

            //     <ChessTacticsQuestion
            //     startPosition={"5r2/5ppk/1Q2p1p1/4P3/p1r5/5qP1/PP3P1P/2RR2K1 w KQkq - 0 1"} 
            //     answerMoves = {[
            //         "Rxc4",
            //         "Qxd1+",
            //         "Kg2",
            //         "Qd5+"
            //       ]} 
            //     submitAnswer = {(e)=> alert(e.answerValue)}
            //     isPlayer={true}
            // />

            }
        </HashRouter>
    </Provider>
    ,
    document.getElementById('root')
);
register();
