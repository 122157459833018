import React from "react";
import { observer } from "mobx-react-lite";

import Letter from "./Letter";
import { questionState } from "../state/questionState";

const GuessWords = () => {
  const renderSentence = () => {
    const rows = [];
    const sentence = questionState.sentence;

    let words = sentence.split(" ");
    let MAX_LETTERS_IN_A_ROW = 10;
    let remainingLettersinRow = MAX_LETTERS_IN_A_ROW;
    let wordToPush = ""

    for (let i = 0; i < words.length; i++) {

      let currentwordLength = words[i].length;

      if (remainingLettersinRow >= currentwordLength) {

        wordToPush = wordToPush + words[i] + " ";
        remainingLettersinRow = remainingLettersinRow - currentwordLength - 1;

      } else {

        rows.push(renderRow(wordToPush));
        wordToPush = words[i] + " ";
        remainingLettersinRow = MAX_LETTERS_IN_A_ROW - currentwordLength - 1;
      }

    }

    if(wordToPush.length > 0) rows.push(renderRow(wordToPush));

    return rows;
  };

  const renderRow = (word) => {
    const letters = word
      .split("")
      .map((letter, index) => (
        <Letter key={index} isSpace={letter === " "} letter={letter} />
      ));

    return (
      <div className="flex items-center justify-center" key={word}>
        {letters}
      </div>
    );
  };

  return <div className="flex flex-col">{renderSentence()}</div>;
};

export default observer(GuessWords);
