import React, { Component } from 'react';
import { Statistic } from 'antd';
import 'antd/lib/statistic/style/index.css'



export default class TimerCountdown extends Component {
  constructor(props) {
    super(props);
    this.state = { deadline: Date.now() +  props.timeForQuestion * 1000 };
  }

  render() {

    const { Countdown } = Statistic;

    return (
        <Countdown format="s" value={this.state.deadline} valueStyle={
          {"color":"#00f7f7", 
          "font-weight" : "bold", 
          "font-size":"1.25em"}
        }/>
    );
  }
}