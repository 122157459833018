import React, { useState, useEffect } from "react";

import Chessboard from 'chessboardjsx';
import Chessground from "react-chessground"
import "react-chessground/dist/styles/chessground.css"

import Chess from "chess.js";

const ChessTacticsSolution = ({ startPosition, answerMoves }) => {

  const [position, setPosition] = useState(startPosition);
  const [currentMove, setCurrentMove] = useState(0);
  const [history, setHistory] = useState([startPosition]);

  useEffect(() => {

    let historyObj = [];

    let game = new Chess(startPosition);
    historyObj.push(game.fen());

    for (let i = 0; i < answerMoves.length; i++) {
      game.move(answerMoves[i]);
      historyObj.push(game.fen());
    }

    setHistory(historyObj);
    setCurrentMove(historyObj.length);

    for (let i = 0; i <= answerMoves.length; i++) {
      setTimeout(() => {
        setPosition(historyObj[i]);
      }, 1000 + 1000 * i);
    }

  }, []);

  const handlePrevMove = () => {
    if (currentMove <= 1) return;

    console.log("prev move", currentMove, history[currentMove - 1]);
    setPosition(history[currentMove - 2]);
    setCurrentMove((currentMove) => currentMove - 1);

  }

  const handleNextMove = () => {

    if (currentMove >= history.length) return;

    console.log("next move", currentMove, history[currentMove - 1]);
    setTimeout(() => setPosition(history[currentMove]), 0);
    setCurrentMove(currentMove + 1);

  }


  return (


    <div className="flex flex-col w-full justify-center items-center">

      <p className="text-xl">Solution</p>

      <Chessboard position={position}
        width="350"
        onDrop={true}
        orientation={startPosition.includes("w") ? "black" : "white"}
      />
{/* 
        <Chessground
            width="350px"
            height="350px"
            // turnColor={this.turnColor()}
            // movable={this.calcMovable()}
            // lastMove={lastMove}
            fen={position}
            onMove={true}
            // ref={el => {
            //   this.chessground = el
            // }}
          />


      {position} <br />
      {currentMove} <br />

      <div className="h-8 items-center gap-1">
        <button className="btn btn-primary" onClick={handlePrevMove}>{"<"}</button>
        <button className="btn btn-primary" onClick={handleNextMove}>{">"}</button>

      </div> */}

    </div>
  );
};

export default ChessTacticsSolution;
