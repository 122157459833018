import React from 'react';
import { css } from 'glamor';
import { DownCircleOutlined } from '@ant-design/icons'
import ScrollToBottom, { useScrollToBottom, useSticky } from 'react-scroll-to-bottom';


import Message from './Message/Message';

const ROOT_CSS = css({
  flex: "auto",
  overflow: "auto",
  "padding-right": "0.5rem"
});


const Messages = ({ messages, name }) => {
  return (

    <ScrollToBottom className={ROOT_CSS}>

      <MessagesContent messages={messages} name={name} />

    </ScrollToBottom>
  )
}

const MessagesContent = ({ messages, name }) => {

  const scrollToBottom = useScrollToBottom();
  const [sticky] = useSticky();

  return (
    <div className="flex flex-col flex-1 pl-2 overflow-y-auto overflow-x-hidden">

      <ScrollToBottom className={ROOT_CSS}>
        <div className="absolute bottom-0 right-0">
          {!sticky && <DownCircleOutlined onClick={scrollToBottom} />}
        </div>

        {messages.map((message, i) => <Message message={message} prevMessage={messages[i-1]} name={name} />)}

      </ScrollToBottom>

    </div>
  );
}

export default Messages;