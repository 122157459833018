import React from "react";
import 'antd/lib/table/style/index.css'

import { Table } from "antd";
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons';


const columns = [
  {
    title: 'Player',
    dataIndex: 'nickname',
    render: text => <strong>{text}</strong>
  },
  {
    title: 'Correct?',
    dataIndex: 'answeredCorrect',
    render: correct => correct? <CheckCircleTwoTone style={{ fontSize: '30px'}} twoToneColor="#52c41a" /> : <CloseCircleTwoTone style={{ fontSize: '30px'}}  twoToneColor="#eb2f96" />
  },
  {
    title: 'Score',
    dataIndex: 'score'
  },
  {
    title: 'Time',
    dataIndex: 'answerTimestamp',
    render: time => `${time}s`
  },
  {
    title: 'Answer',
    dataIndex: 'answerGiven',
    render: (answer) => {
      if (answer.answerType === "VOICE") {
        let blob = answer.answerValue.blob;
        let blobURL = URL.createObjectURL(new Blob([blob]));
        return <audio controls="controls" src={blobURL} type="audio/mp3" />
      } else {
        return <span> {answer.answerValue} </span>
      }
    }
  }
];


export default function QuestionSummary() {

  const gameState = useSelector(state => state.gameState);
  const rows = gameState.currentQuestion.playersWhoHaveAnswered.map(winner => _.pick(winner, "nickname", "answeredCorrect", "score", "answerTimestamp", "answerGiven"));

  return (
    <Table
      size = "middle"
      columns={columns}
      dataSource={rows}
      pagination={{ hideOnSinglePage: true }}
    />
  );
}