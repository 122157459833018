import React, {useState, useEffect} from "react";
import { observer } from "mobx-react-lite";
import SimpleKeyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import { hangmanGuessState } from "../state/hangmanGuessState";
import { questionState } from "../state/questionState";

import './Keyboard.css'

import UIfx from 'uifx'
import tickAudioFile from "../../../../../../assets/sounds/tick.mp3"
const tickSound = new UIfx(tickAudioFile);

const Keyboard = () => {

  const [key, setKey] = useState(null);

  const handleUserInput = (loweredKey) => {
    tickSound.play();

    if (questionState.result) return questionState.reset();
    if (hangmanGuessState.isChecking) return;

    if (loweredKey.length === 1 && loweredKey.match(/[a-z]/)) {
      // setKey(loweredKey);

      const condition = hangmanGuessState.addGuessedLetter(loweredKey);
      // if (condition === "correctGuess")
      //   console.log("correct");
      //   // setTimeout(() => {
      //   //   setKey("");
      //   // }, 1000);
      // else if (condition === "wongGuess") {
      //   console.log("wrong");
      //   // setTimeout(() => {
      //   //   setKey("");
      //   // }, 1000);
      // }
    }
    // else setKey("invalid");
  }

  const keyboardEventHandler = (event) => {
    handleUserInput(event.key.toLowerCase());
  }

  useEffect(() => {
    if(process.env.NODE_ENV === 'development') {
      document.addEventListener("keypress", keyboardEventHandler);
      return () => document.removeEventListener("keypress", keyboardEventHandler);  
    }
  }, []);


  const onKeyPress = (button) => {
    //document.dispatchEvent(new KeyboardEvent("keypress", { key: button }));

    //Added 
    handleUserInput(button.toLowerCase());


  };




  return (
    <div className="flex flex-col w-full px-2 items-center justify-center">
      <SimpleKeyboard
        onKeyPress={onKeyPress}
        theme="hg-theme-default hg-theme-ios"
        buttonTheme={[
          {
            class: "text-black",
            buttons: "Q W E R T Y U I O P A S D F G H J K L Z X C V B N M"
          }, {
            class: "hg-blue",
            buttons: hangmanGuessState.correctGuessString
          }, {
            class: "hg-red",
            buttons: hangmanGuessState.wrongGuessString
          }
        ]}
        layout={{
          default: ["Q W E R T Y U I O P",
            "A S D F G H J K L",
            "Z X C V B N M"]
        }}
      />
    </div>
  );
};

export default observer(Keyboard);
