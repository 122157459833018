import React, {useEffect} from 'react';
import axios from 'axios';
import queryString from 'query-string';


const ZoomPageMain = (props) => {

    useEffect(() => {

        let queryParams = queryString.parse(props.location.search);

        console.log(queryParams);

        if(queryParams.code) {

            let zoomCode = queryParams.code;

            console.log('zoomCode', zoomCode)

            let url = "https://www.kweej.com/zoomcall?code=" + zoomCode;

            console.log('url :>> ', url);

            axios.get(url).then((res) => {
                console.log(res);
            }).catch(err => {
                console.log(err);
            })

        }




    }, []);

    return (
        <div>
            Hello all
        </div>
    )

}

export default ZoomPageMain;
