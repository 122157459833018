import React, { useState, useEffect, createContext, useRef } from 'react';
import { useSelector, useDispatch, useStore } from 'react-redux';
import { setSolution, updateGameState, selectedPin, handleNickname, setHost, newChatMessage, setAnswerSubmitted, setChatModalVisible } from '../../utils/ducks/Reducer';
import Chat from './Chat/Chat.js'
import _ from 'lodash';
import CenterPanel from './CenterPanel'
import AnnounceSplash from '../../utils/components/AnnounceSplash/AnnounceSplash'
import { announceSplashState } from '../../utils/components/AnnounceSplash/AnnounceSplashState'

import Leaderboard from './Leaderboard';
import kweejLogo from '../../assets/KweejLogo.svg'

import { observer } from "mobx-react-lite";


import UIfx from 'uifx'
import tickAudioFile from "../../assets/sounds/tick.mp3"
import { browserName } from 'react-device-detect';

import { toast } from 'react-toastify';
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import { useWindowSize } from '../../utils/util'

import { FloatChatBox } from './Chat/FloatChatBox';
import { FloatChatMessage } from './Chat/FloatChatBox';

import { message } from 'antd';
import 'antd/lib/message/style/index.css'


import getSocket from "../../utils/socket.js";
import StateMonitor from '../../utils/components/AnnounceSplash/StateMonitor';

let socket = null;

const tickSound = new UIfx(tickAudioFile);

const GamePageMain = (props) => {

    const store = useStore();

    const pin = useSelector(state => state.selectedPin);
    const nickname = useSelector(state => state.nickname);
    const gameState = useSelector(state => state.gameState);
    const isPlayer = useSelector(state => state.isPlayer);

    const dispatch = useDispatch();

    //Prevent page refresh and naviagtion without warning
    useEffect(() => {
        onbeforeunload = (e) => {
            if (process.env.NODE_ENV === "production" && gameState && !gameState.isGameOver) e.returnValue = "Don't leave";
        }


        // returned function will be called on component unmount 
        return () => {
            onbeforeunload = null;
        }


    }, [])

    useEffect(() => {

        socket = getSocket();

        socket.on('room-joined', ({ player, gameState }) => {
            dispatch(updateGameState(gameState));
        });

        socket.on('host-determined-answer', (data) => {
            dispatch(updateGameState(data.gameState));
        })

        socket.on('player-answer', (data) => {
            dispatch(updateGameState(data.gameState));

        })
        
        socket.on('question-over', (data) => {
            dispatch(updateGameState(data.gameState));
            dispatch(setSolution(data.solution));
        })

        socket.on('next-question', (data) => {
            dispatch(setAnswerSubmitted(false))
            let gameState = data.gameState;
            dispatch(updateGameState(gameState));
        })

        socket.on('splash-screen', (data) => {
            let { splashScreenData } = data;

            let { type, message, autoDismiss } = splashScreenData;

            console.log('splashScreenData :>> ', splashScreenData);

            announceSplashState.init(message, true, autoDismiss, false, 2000);

        })

        socket.on('splash-dismiss', (data) => {

            announceSplashState.hide();

        })

        socket.on('pong', (latency) => {
            console.log('latency is :>> ', latency);
        })


        socket.on('message-recd', message => {
            dispatch(newChatMessage(message));
            tickSound.setVolume(0.1).play();
            if (!(message.sender === "system" || message.sender === nickname) && !store.getState().chatModalVisible) toast(<FloatChatMessage sender={message.sender} message={message.text} />);
        });

        socket.on('host-disconnected', () => {
            message.error("The host has disconnected");
            socket.disconnect();
        });

        socket.on('disconnect', (reason) => {
            if (reason === 'io server disconnect') {
                // the disconnection was initiated by the server, you need to reconnect manually
                message.error("Server disconnected, trying to reconnect manually");

                socket.connect();
            }
            // else the socket will automatically try to reconnect
            message.error("Got disconnected, trying to reconnect");

        });

        socket.on('reconnect', (attemptNumber) => {
            message.success("Reconnected !");
          });


        return () => {
            socket.disconnect();
        }

    }, []) //call only once

    const submitAnswer = (answerVal) => {
        console.log('submitted answer :>> ', answerVal);
        socket.emit('question-answered', { name: nickname, answer: answerVal, pin: pin });
        if (!gameState.currentQuestion.isHostDetermined) dispatch(setAnswerSubmitted(true))

    }

    const startGame = () => {
        if (gameState.playersList.length > 1) {
            socket.emit('game-start', { pin: pin });
        } else {
            alert('You need at least 2 players to start')
        }
    }

    const nextQuestion = (pin) => {
        socket.emit('next-question', { "pin": pin })
    }

    const endQuestion = (pin) => {
        socket.emit('end-question', { "pin": pin })
    }

    const handleSplashDismissClick = () => {

        socket.emit('dismiss-splash', { "pin": pin });
    }

    const chatModalVisible = useSelector(state => state.chatModalVisible);
    const showChatModal = () => { dispatch(setChatModalVisible(true)); };

    const ChatDrawerButton = ({ drawerDivRef }) => {

        const windowSize = useWindowSize();

        return (

            (windowSize.width < 768) &&

            <div>
                {!chatModalVisible &&
                    <FloatChatBox />

                }
                <div className="absolute top-2/5 right-0 z-20">

                    <button
                        className="mx-1 flex justify-center items-center p-0 w-12 h-12 bg-purple-400 rounded-full shadow transition ease-in duration-200 focus:outline-none transform transition duration-300 ease-in-out select-none"
                        onClick={showChatModal}
                    >
                        <i className="far fa-comment" color="white"></i>
                    </button>


                    <SwipeableDrawer
                        container={drawerDivRef}
                        disableSwipeToOpen={false}
                        disableDiscovery
                        hysteresis={0.1}
                        anchor="right"
                        minFlingVelocity={250}
                        swipeAreaWidth={10}
                        open={chatModalVisible}
                        onClose={() => dispatch(setChatModalVisible(false))}
                        onOpen={() => dispatch(setChatModalVisible(true))}
                    >
                        <div className="flex flex-col h-full" style={{ width: windowSize.width * 0.9 }}>
                            <div className="h-10 justify-left">
                                <button
                                    className="mt-2 absolute right-0 flex justify-center items-center p-0 w-8 h-8 bg-opacity-0 select-none"
                                    onClick={() => dispatch(setChatModalVisible(false))}
                                >
                                    <i className="far fa-times-circle fa-lg" color="gray"></i>
                                </button>
                            </div>
                            <Chat showHeader={false} />

                        </div>

                    </SwipeableDrawer>

                </div>

            </div>


        )

    }


    const [container, setContainer] = React.useState(null);
    const handleRef = React.useCallback(instance => setContainer(instance), [setContainer])

    return (

        <div>
            <StateMonitor />
            <div ref={handleRef} className="flex w-full h-screen antialiased bg-gray-200">
                <div className="flex flex-col hidden h-screen border-r border-gray-300 shadow-xl md:block w-3/12 transform transition-all duration-500 ease-in-out px-4 overflow-y-auto bg-teal-300">
                    <img className="w-40 mt-4" src={kweejLogo} alt="logo" />
                    <Leaderboard />
                </div>
                {/* <FloatChatBox /> */}
                <ChatDrawerButton drawerDivRef={container} />
                {announceSplashState.showing ?
                    <AnnounceSplash handleSplashDismissClick={handleSplashDismissClick} />
                    :
                    <CenterPanel nextQuestion={nextQuestion} endQuestion={endQuestion} submitAnswer={submitAnswer} startGame={startGame} />
                }

                <div className="hidden w-4/12 h-screen md:flex flex-col">
                    <Chat showHeader />
                </div>
            </div>

        </div>

    );
}

export default observer(GamePageMain);