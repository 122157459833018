import React from 'react';
import _ from 'lodash';

import ChessTacticsSolution from './QuestionDisplay/SolutionOptions/ChessTacticsSolution/ChessTacticsSolution.js';


const getSolutionDisplay = (answerFormat, questionDetails, solution) => {

    if (answerFormat === 'CHESSPUZZLE') {
        return <ChessTacticsSolution
            startPosition={questionDetails.startPosition}
            answerMoves={questionDetails.solution} />
    } else {
        return <p className="text-xl">Correct answer : {solution}</p>
    }

}

export default function ShowSolution({ answerFormat, questionDetails, solution }) {


    return (
        <div>
            <div className="flex justify-center items-center">
                {getSolutionDisplay(answerFormat, questionDetails, solution)}
            </div>
        </div>
    )
}