import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import Messages from './Messages/Messages';
import Input from './Input/Input';
import ChatHeader from "./ChatHeader/ChatHeader";
import UIfx from 'uifx'
import tickAudio from "../../../assets/sounds/tick.mp3"

import getSocket from "../../../utils/socket.js"

let socket = null;
const tickSound = new UIfx(tickAudio);



export default function Chat({showHeader}) {
  const [message, setMessage] = useState('');
  const messages = useSelector(state => state.messages);

  const pin = useSelector(state => state.selectedPin);
  const nickname = useSelector(state => state.nickname);

  useEffect(() => {
    
    console.log("Getting socket in Chat")

    socket = getSocket();

  }, [])


  const sendMessage = (event) => {
    event.preventDefault();

    //If empty message dont do anything
    if(message.trim().length === 0) {
      return;
    }

    if (message) {
      socket.emit('message-sent', { pin, message, nickname }, () => {
        setMessage('');
        //tickSound.setVolume(0.05).play();
      });

    }
  }


  return (
    <div className="flex flex-col h-full justify-between bg-gray-200 transition-all duration-500 ease-in-out">

      {showHeader && <ChatHeader />}
      <Messages messages={messages} name={nickname} />
      <Input message={message} setMessage={setMessage} sendMessage={sendMessage} />
    </div>
  );
}