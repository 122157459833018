import React from 'react';
import { getHashColor } from '../../../../../utils/util';



const SystemMessage = ({ message }) => {
  return (
    <div className="flex flex-col px-3 py-1 m-auto">
      <div className="self-center px-2 py-1 mx-0 mb-1 text-sm bg-teal-100 shadow rounded-tg">{message}</div>
    </div>
  )
}

const OwnMessage = ({ message, prevMessageSender }) => {
  return (
    <div className="flex flex-row justify-end">
      <div className="messages text-sm grid grid-flow-row gap-2">
        <div className="flex items-center mb-1 flex-row-reverse group">
          <p className="px-4 py-1 rounded-t-lg rounded-l-lg bg-green-200 shadow max-w-xs lg:max-w-md">{message}</p>
        </div>
      </div>
    </div>
  )
}

const OtherMessage = ({ message, prevMessageSender, sender }) => {

  const isSentBySameSender = () => {
    return (sender === prevMessageSender) ? true : false;
  }

  return (
    <div className="flex flex-row justify-start">
      <div className="messages text-sm grid grid-flow-row gap-2">
        <div className={'flex mb-1 items-center group '.concat(!isSentBySameSender()? 'mt-1' : '')}>
          <p className="px-4 py-1 rounded-t-lg rounded-r-lg bg-white shadow max-w-xs lg:max-w-md">
            {!isSentBySameSender() &&
              <div>
                <span className={`text-${getHashColor(sender)} font-bold`}>{sender}</span>
                <br />
              </div>}
            <span>{message}</span>
          </p>
        </div>
      </div>
    </div>
  )
}


const Message = ({ message, prevMessage, name }) => {
  let isSentByCurrentUser = false;
  let isSentBySystem = false;

  const trimmedName = name.trim().toLowerCase();

  if (message.sender.trim().toLowerCase() === trimmedName) {
    isSentByCurrentUser = true;
  } else if (message.sender === "system") {
    isSentBySystem = true;
  }



  return (
    isSentByCurrentUser
      ? (
        <OwnMessage message={message.text} prevMessageSender={prevMessage? prevMessage.sender : null} />
      )
      : isSentBySystem ? (
        <SystemMessage message={message.text} />

      ) : (
          <OtherMessage message={message.text} prevMessageSender={prevMessage? prevMessage.sender : null} sender={message.sender} />
        )
  );
}

export default Message;