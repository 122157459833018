import React from 'react';
import MCQ4Question from './AnswerOptions/MCQ4Question';
import MCQ2Question from './AnswerOptions/MCQ2Question';
import CodeQuestion from './AnswerOptions/CodeQuestion';
import VoiceQuestion from './AnswerOptions/VoiceQuestion';
import TextQuestion from './AnswerOptions/TextQuestion';
import AnagramQuestion from './AnswerOptions/AnagramQuestion/AnagramQuestion';
import HangmanQuestion from './AnswerOptions/HangmanQuestion/HangmanQuestion'
import ChessTacticsQuestion from './AnswerOptions/ChessTacticsQuestion/ChessTacticsQuestion';
import Chess from 'chess.js';


function getQuestionTypeComponent(answerFormat, questionDetails, submitAnswer, isPlayer) {

    let type = answerFormat;

    if (type === 'MCQ2') {
        return <MCQ2Question questionDetails={questionDetails} submitAnswer={submitAnswer} isPlayer={isPlayer} />

    } else if (type === 'MCQ4') {
        return <MCQ4Question questionDetails={questionDetails} submitAnswer={submitAnswer} isPlayer={isPlayer} />

    } else if (type === 'TEXT') {
        return <TextQuestion questionDetails={questionDetails} submitAnswer={submitAnswer} isPlayer={isPlayer} />

    } else if (type.startsWith('CODE')) {
        let codeLength = parseInt(type.substr(4, type.length));
        return <CodeQuestion questionDetails={questionDetails} submitAnswer={submitAnswer} isPlayer={isPlayer} codeLength={codeLength} />

    } else if (type.startsWith('NUMCODE')) {
        let codeLength = type.substr(4, type.length).parseInt();
        return <CodeQuestion questionDetails={questionDetails} submitAnswer={submitAnswer} isPlayer={isPlayer} codeLength={codeLength} isInputNum={true} />

    } else if (type === 'VOICE') {
        return <VoiceQuestion questionDetails={questionDetails} submitAnswer={submitAnswer} isPlayer={isPlayer} />

    } else if (type === 'ANAGRAM') {
        return <AnagramQuestion mode="ANAGRAM" sentence={questionDetails.sentence} submitAnswer={submitAnswer} isPlayer={isPlayer} />

    } else if (type === 'HANGMAN') {
        return <HangmanQuestion submitAnswer={submitAnswer} isPlayer={isPlayer} answer={questionDetails.answer} />
    } else if (type === 'CHESSPUZZLE') {

        return <ChessTacticsQuestion 
            submitAnswer={submitAnswer} 
            isPlayer={isPlayer} 
            startPosition={questionDetails.startPosition}
            answerMoves={questionDetails.solution} 
            game={new Chess(questionDetails.startPosition)}
        />

    }

}

export default function AnswerPanel({ answerFormat ,questionDetails, submitAnswer, isPlayer }) {
    return (

        <div className="absolute bottom-0 bg-white rounded-t-lg pt-0 pb-1 w-full">

            <div className="flex flex-row justify-center items-center flex-shrink-0">
                {getQuestionTypeComponent(answerFormat, questionDetails, submitAnswer, isPlayer)}
            </div>

        </div>

    )
}