import React, { Component } from 'react';

import './AnagramQuestion.css';
import { Letters } from './Letters/Letters';
import { Submit } from './Submit/Submit';

import UIfx from 'uifx'
import tickAudioFile from "../../../../../assets/sounds/tick.mp3"
const tickSound = new UIfx(tickAudioFile);

const isScreenSmall = () => false;

const generateID = () => `_${Math.random().toString(36).substr(2)}`;

const shuffleArray = (arr) => {
    const newArr = arr;
    let currIndex = arr.length;
    let tempValue;
    let randomIndex;

    while (currIndex) {
        randomIndex = Math.floor(Math.random() * currIndex);
        currIndex -= 1;

        tempValue = newArr[currIndex];
        newArr[currIndex] = newArr[randomIndex];
        newArr[randomIndex] = tempValue;
    }

    return newArr;
};

class AnagramQuestion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            letters: [],
            textAnswer: "",
            currentAnswer: [],
            dragActive: this.getDragActive(),
        };

        this.handleTextAnsInput = this.handleTextAnsInput.bind(this);

    }

    componentDidMount() {

        window.addEventListener('resize', this.debouncedResizeListener);

        const { sentence, mode } = this.props;

        let wordsArr = sentence.split(" ");

        let index = 0;
        let lettersCopyArr = [];

        for (index = 0; index < wordsArr.length; index++) {
            let shuffledArray = this.initLetters(wordsArr[index])
            lettersCopyArr[index] = shuffledArray;

        }

        this.setState({
            letters: lettersCopyArr,
        }, this.initCurrentAnswer);

    }


    getDragActive = () => {
        const { mode } = this.props;
        return (mode === 'TEXT' ? false : true);
    };


    initLetters = (word) => {

        const lettersObjects = word.split('')
            .map((letter) => ({
                value: letter,
                id: generateID(),
                active: true,
            }));

        let shuffledArray = shuffleArray(lettersObjects);

        return shuffledArray;

    };

    handleTextAnsInput = (e) => {
        this.setState({
            textAnswer: e.target.value
        })

    }

    initCurrentAnswer = () => {
        if (isScreenSmall() || this.props.mode != 'ANAGRAM') {
            return;
        }
        let lettersCopyArr = Object.assign({}, this.state.letters);


        this.setState({
            currentAnswer: lettersCopyArr,
        });
    };


    handleDragEnd = (result) => {

        tickSound.play();

        const { source, destination, draggableId } = result;
        const { letters } = this.state;

        if (!destination || destination.index === source.index) {
            return;
        }

        let letterNo = source.droppableId.substr("drop".length);
        let lettersArrCopy = Array.from(letters);
        let draggedLetter = lettersArrCopy[letterNo];
        const movedLetter = draggedLetter[source.index];
        const updatedLetters = draggedLetter
            .filter((letter) => letter.id !== draggableId);
        updatedLetters.splice(destination.index, 0, movedLetter);

        lettersArrCopy[letterNo] = updatedLetters;

        this.setState({
            letters: lettersArrCopy,
            currentAnswer: [...updatedLetters],
        });

    };


    onSubmit = () => {
        // const { currentAnswer } = this.state;

        if (this.props.mode === "TEXT") {
            this.props.submitAnswer(
                {
                    answerType: "TEXT",
                    answerValue: this.state.textAnswer.trim()
                });
            return;
        }

        let ansSentence = "";

        let currentAnswerCopy = Array.from(this.state.letters);



        currentAnswerCopy.forEach(letterArr => {
            let joinLetterArr = this.joinLetters(letterArr)
            ansSentence = ansSentence + ' ' + joinLetterArr;
        })

        this.props.submitAnswer(
            {
                answerType: "TEXT",
                answerValue: ansSentence.trim()
            }
        );
    };

    joinLetters = (arr) => arr.reduce((a, b) => a + b.value, '');

    render() {
        // const loading = false, error = false;
        const {
            letters,
            dragActive,
            textAnswer,
        } = this.state;


        const { mode, isPlayer } = this.props;

        return (
            <div className="flex flex-col items-center justify-center">
                {
                    letters.map((letter, index) => {
                        return (
                            <Letters
                                passedLetters={letter}
                                letterNo={index}
                                clicked={this.handleLetterClick}
                                dragActive={dragActive && !(mode === 'CODE')}
                                dragEnd={this.handleDragEnd}
                            />)
                    })
                }
                <div className="flex flex-col justify-center items-center">
                    {mode === 'TEXT' &&
                        <input type="text" value={textAnswer} placeholder="Type answer here" onChange={this.handleTextAnsInput} ></input>
                    }
                    {isPlayer && <Submit
                        onSubmit={this.onSubmit}
                    />}

                </div>

            </div>

        );
    }
}


export default AnagramQuestion;
