import React from 'react';
import ReactPlayer from 'react-player';
import { FloatChatBox } from '../Chat/FloatChatBox';

function getMediaTypeComponent(assetDetails) {
    switch (assetDetails[0].assetType) {
        case 'image':
            return <img src="absolute w-full object-contain bg-black" src={assetDetails[0].assetURL} alt="questionimage"></img>
        case 'audio':
            return <ReactPlayer url={assetDetails[0].assetURL} playing="true" />
        case 'video':
            return <ReactPlayer url={assetDetails[0].assetURL} playing="true" />
        case 'text' :
            return <p className="text-center text-xl xl:text-2xl mx-2 my-3" style={{whiteSpace : "pre-line"}}>
                {assetDetails[0].text}
            </p>
        default:
            return <ReactPlayer url={assetDetails[0].assetURL} playing="true" />
    }
}

export default function QuestionDisplay({ questionTitle, questionAssets }) {
    return (

        <div>

            <div className="flex flex-col relative items-center bg-white">
        

                {questionTitle &&

                    <p className="text-center text-xl lg:text-2xl xl:text-3xl mx-3 my-4">
                        {questionTitle}
                    </p>
                }
                {questionAssets &&
                    <div className="flex justify-center flex-col" id="asset-section">
                        <a className="block justify-center relative w-full max-w-sm flex flex-shrink-0">
                            {getMediaTypeComponent(questionAssets)}
                        </a>
                    </div>
                }
            </div>

        </div>
    )
}