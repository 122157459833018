import { action, observable, decorate } from "mobx";
import Chess from 'chess.js'

class ChessGameState {

  position = "start";
  game = null;

  constructor(startPosition = "start") {
    this.position = this.startPosition;
    this.game = new Chess(startPosition);
  }

  reset() {
    this.position = "start";
    this.game = null;
  }

  setPosition(position) {
    this.position = position;
  }

  init(startPosition) {
    console.log("Init : ", startPosition);
    this.position = startPosition;
    this.game = new Chess(startPosition);
    console.log("Inited game : ", this.game);
    console.log(this.game.ascii());
    console.log(this.game.fen());
  }

}

decorate(ChessGameState, {
    position: observable,
    game: observable,
    setPosition: action
  })

export const chessGameState = new ChessGameState();
