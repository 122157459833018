
import React from 'react';

const QuizCard = ({ quizPoster, quizTitle, quizDescription, tags, creatorName, handleQuizPlay }) => {


    return (
        <div class="flex flex-col justify-between w-full max-w-sm bg-white rounded-lg shadow-lg my-2 mx-2">

            <img class="rounded-t-lg" src={quizPoster} alt="poster" />

            <div class="flex flex-col h-full px-3 py-3 justify-between">

                <p class="text-xl font-bold">{quizTitle}</p>

                <p class="my-1 text-base">{quizDescription}</p>

                <div id="tags" class="flex flex-row flex-wrap my-3">
                    {tags.map(tag => (
                        <div class="flex justify-between">
                            <div class="bg-purple-200 rounded-full px-4  mr-2 py-1">
                                {tag}
                            </div>
                        </div>
                    ))}

                </div>

                {creatorName &&

                    <div class="flex flex-row items-center justify-between space-x-2 my-2 h-10 border-t pt-1">

                        {/* <div class="flex flex-row space-x-2">
                            <div class="flex h-8 px-2 text-gray-600 justify-start items-center rounded">
                                <i class="fas fa-thumbs-up text-lg mr-1"></i>
                                <span class="">200</span>
                            </div>

                            <div class="flex h-8 px-2 gray-200 text-gray-600 justify-start items-center rounded">
                                <i class="fas fa-thumbs-down text-lg mr-1"></i>
                                <span class="">36</span>
                            </div>

                        </div>
                    */}
                        <div class="text-purple-600">
                            {creatorName}
                        </div>

                    </div>
                }
            </div>

            <div class="flex flex-row justify-between divide-x">

                <div class="flex justify-center items-center w-16 h-12 text-xl bg-purple-400 rounded-l text-white">
                    <i class="fas fa-heart"></i>
                </div>

                <div class="btn btn-primary w-full text-center"
                    onClick={handleQuizPlay}>
                    Play
                </div>

            </div>


        </div>

    )


}


export default QuizCard;



