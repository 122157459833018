import { createStore, applyMiddleware } from 'redux';
import reducer from './Reducer';
import promise from 'redux-promise-middleware';
import { composeWithDevTools } from 'redux-devtools-extension';
// import { loadState, saveState } from './sessionStorage';

// const persistedState = loadState();

// console.log('persistedState :>> ', persistedState);

// const store = createStore(reducer, persistedState);



// store.subscribe(() => {
//     saveState(store.getState());
//   });

const store = createStore(reducer, composeWithDevTools(applyMiddleware(promise)));


export default store;