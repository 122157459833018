import React from 'react';
import _ from 'lodash';

function handleAnswer(submitAnswer, ansValue) {
    submitAnswer({
        answerType: "TEXT",
        answerValue: ansValue
    });
}

const ChoiceComponent = ({ submitAnswer, questionDetails, isPlayer, choiceNo }) => {

    let choiceText = questionDetails['choice' + choiceNo];
    let colorStyle = "";

    switch (choiceNo) {
        case 1:
            colorStyle = "bg-orange-200 hover:bg-orange-300"
            break;
        case 2:
            colorStyle = "bg-blue-200 hover:bg-blue-300"
            break;

        case 3:
            colorStyle = "bg-green-200 hover:bg-green-300"
            break;

        case 4:
            colorStyle = "bg-purple-200 hover:bg-purple-300"
            break;

        default:
            colorStyle = "bg-orange-200 hover:bg-orange-300"


    }

    return (
        <button className="w-1/2 focus:outline-none" onClick={() => { if (isPlayer) handleAnswer(submitAnswer, choiceText) }}>
            <p className={"text-center md:text-lg lg:text-xl shadow rounded px-2 py-2 m-1 h-16 " + colorStyle}>{choiceText}</p>
        </button>

    )
}

//Randomize options display sequence
const choiceSquence = _.shuffle([1,2,3,4]);

export default function MCQ4Question({ submitAnswer, questionDetails, isPlayer }) {
    return (


        <div className="flex flex-row flex-wrap">

            {
                choiceSquence.map((choice) => {
                    return <ChoiceComponent submitAnswer={submitAnswer} questionDetails={questionDetails} isPlayer={isPlayer} choiceNo={choice} />
                })
            }

        </div>

    )
}