import React, { useState, useEffect, createContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import QuestionDisplay from './QuestionDisplay/QuestionDisplay';
import PlayersAnsweredPanel from './PlayersAnsweredPanel';



import { Spin } from 'antd';
import 'antd/lib/spin/style/index.css'

import PlayerQuestionOver from './PlayerQuestionOver';
import _ from 'lodash';
import { getGameStatus } from '../../utils/util'

import QuestionSummary from './QuestionSummary';
import QuestionHeader from './QuestionHeader';
import AnswerPanel from './QuestionDisplay/AnswerPanel';
import WinnerPicker from './WinnerPicker';
import PodiumWinners from './PodiumWinners';
import TextWithCopyLink from '../../utils/components/TextWithCopyLink';
import ShowSolution from './ShowSolution';

export default function CenterPanel({ submitAnswer, endQuestion, nextQuestion, startGame }) {

    const answerSubmitted = useSelector(state => state.answerSubmitted);
    const pin = useSelector(state => state.selectedPin);
    const nickname = useSelector(state => state.nickname);
    const gameState = useSelector(state => state.gameState);
    const isHost = useSelector(state => state.isHost);
    const isPlayer = useSelector(state => state.isPlayer);
    const solution = useSelector(state => state.solution);

    const WaitingForResultsPanel = () => {
        return (
            <div className="flex flex-col items-center justify-center" id="waiting-for-results" >
                <br />
                <p>Waiting for others to submit answers!!</p>
                <Spin size="large" />
            </div>
        )
    }

    const HostGameStarter = () => {
        return (
            <div className="flex flex-col items-center justify-center">
                <TextWithCopyLink text={window.location.origin + "#/?pin=" + pin} />
                <button className="btn btn-primary" type="button" onClick={() => startGame()}>
                    Start game
                </button>
            </div>
        )
    }

    const GameStartSpashPanel = () => {
        return (
            <div className="p-2">
                <QuestionHeader displayChat={true} displayLeaderboard={true} displayTimer={false} title={"PIN :".concat(pin)} />
                {gameState.quizInfo &&
                    <div className="flex flex-col items-center rounded justify-center ">

                        <p>{gameState.quizInfo.quizName}</p>
                        <p>{gameState.quizInfo.info}</p>
                        <p>Category : {gameState.quizInfo.category}</p>
                        <img className="rounded-lg" src={gameState.quizInfo.quizDisplayAsset} />
                        <p className="text-xl">Please wait for the host to start the game</p>
                    </div>}
            </div>
        )
    }

    const EndQuestionPanel = ({ endQuestion, pin }) => {
        return (
            <div className="flex flex-col justify-center">
                {
                    isHost &&
                    <button className="btn btn-primary"
                        type="button"
                        onClick={() => endQuestion(pin)} >
                        End Question
                    </button>
                }
            </div>
        )
    }

    const NextQuestionPanel = ({ nextQuestion, pin }) => {
        return (
            <div className="flex flex-col justify-center">
                <QuestionSummary />
                {
                    !gameState.autoAdvanceQuestions &&
                    <button className="btn btn-primary"
                        type="button"
                        onClick={() => nextQuestion(pin)} >
                        Next Question
                    </button>
                }
            </div>
        )
    }

    const GAME_NOT_STARTED_PLAYER = "GAME_NOT_STARTED_PLAYER"
    const GAME_NOT_STARTED_HOST = "GAME_NOT_STARTED_HOST"
    const PLAYER_ANSWERING_QUESTION = "PLAYER_ANSWERING_QUESTION"
    const PLAYER_WAITING_FOR_OTHERS_TO_ANSWER = "PLAYER_WAITING_FOR_OTHERS_TO_ANSWER"
    const HOST_AWAITING_ANSWERS_HOST_DETERMINED_QUESTION = "HOST_AWAITING_ANSWERS_HOST_DETERMINED_QUESTION"
    const HOST_AWAITING_ANSWERS_FOR_NORMAL_QUESTION = "HOST_AWAITING_ANSWERS_FOR_NORMAL_QUESTION"
    const PLAYER_QUESTION_OVER = "PLAYER_QUESTION_OVER"
    const HOST_QUESTION_OVER = "HOST_QUESTION_OVER"
    const HOST_GAME_OVER = "HOST_GAME_OVER"
    const PLAYER_GAME_OVER = "PLAYER_GAME_OVER"

    const status = getGameStatus();

    //Check if player has answered correctly (including check for multiple answers any correct)
    const isPlayerAnsweredCorrect = (playerNickname) => {

        for (let i = 0; i < gameState.currentQuestion.playersWhoHaveAnswered.length; i++) {

            let { nickname, answeredCorrect } = gameState.currentQuestion.playersWhoHaveAnswered[i];
            if (nickname === playerNickname && answeredCorrect) return true;
        }
        return false;

    }



    return (

        <div className="flex flex-col relative flex-1 h-screen overflow-auto bg-white shadow-xl transition-all duration-500 ease-in-out">



            {[GAME_NOT_STARTED_HOST, GAME_NOT_STARTED_PLAYER].includes(status) &&
                <GameStartSpashPanel />
            }
            {[GAME_NOT_STARTED_HOST].includes(status) &&
                <HostGameStarter />
            }
            {![GAME_NOT_STARTED_HOST, GAME_NOT_STARTED_PLAYER].includes(status) &&
                <QuestionHeader displayChat={true} displayLeaderboard={true} displayTimer={!gameState.currentQuestion.isQuestionOver} />
            }
            {[PLAYER_ANSWERING_QUESTION, PLAYER_WAITING_FOR_OTHERS_TO_ANSWER, HOST_AWAITING_ANSWERS_FOR_NORMAL_QUESTION, HOST_AWAITING_ANSWERS_HOST_DETERMINED_QUESTION].includes(status) &&
                <PlayersAnsweredPanel />
            }

            {[PLAYER_ANSWERING_QUESTION, PLAYER_WAITING_FOR_OTHERS_TO_ANSWER, HOST_AWAITING_ANSWERS_FOR_NORMAL_QUESTION, HOST_AWAITING_ANSWERS_HOST_DETERMINED_QUESTION, HOST_QUESTION_OVER, PLAYER_QUESTION_OVER].includes(status) &&

                <QuestionDisplay
                    questionTitle={gameState.currentQuestion.questionDetails.questionTitle}
                    questionAssets={gameState.currentQuestion.questionDetails.questionDetails.questionAssets} />
            }

            {[HOST_AWAITING_ANSWERS_FOR_NORMAL_QUESTION].includes(status) &&
                <EndQuestionPanel pin={pin} endQuestion={endQuestion} />

            }
            {[PLAYER_ANSWERING_QUESTION, HOST_AWAITING_ANSWERS_FOR_NORMAL_QUESTION].includes(status) &&
                <AnswerPanel
                    answerFormat={gameState.currentQuestion.questionDetails.answerFormat}
                    questionDetails={gameState.currentQuestion.questionDetails.questionDetails}
                    submitAnswer={submitAnswer}
                    isPlayer={isPlayer} />
            }

            {[HOST_AWAITING_ANSWERS_HOST_DETERMINED_QUESTION].includes(status) &&
                <WinnerPicker
                    winners={gameState.currentQuestion.playersWhoHaveAnswered.map((row, index) => Object.assign(row, { rowNum: index }))} />

            }
            {[PLAYER_WAITING_FOR_OTHERS_TO_ANSWER].includes(status) &&
                <WaitingForResultsPanel />
            }
            {[PLAYER_QUESTION_OVER, HOST_QUESTION_OVER].includes(status) &&
                <ShowSolution
                    answerFormat={gameState.currentQuestion.questionDetails.answerFormat}
                    questionDetails={gameState.currentQuestion.questionDetails.questionDetails}
                    solution={solution}
                />
            }

            {[PLAYER_QUESTION_OVER].includes(status) &&
                <PlayerQuestionOver
                    answeredCorrect={isPlayerAnsweredCorrect(nickname)}
                    answerFormat={gameState.currentQuestion.questionDetails.answerFormat}
                    questionDetails={gameState.currentQuestion.questionDetails.questionDetails}
                    solution={solution}
                />
            }
            {[HOST_QUESTION_OVER].includes(status) && //|| ([PLAYER_QUESTION_OVER].includes(status) && isHost)) && //simplify
                <NextQuestionPanel pin={pin} nextQuestion={nextQuestion} />
            }
            {[HOST_GAME_OVER].includes(status) &&
                <PodiumWinners />
            }
            {[PLAYER_GAME_OVER].includes(status) &&
                <PodiumWinners />
            }
        </div>
    );
}
