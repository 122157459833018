import React from 'react';
import { useAuth0 } from '@auth0/auth0-react'


const CheckAuthentication = ({children}) => {

    const { isAuthenticated, isLoading } = useAuth0();

    console.log(isLoading, isAuthenticated);

    return (
        <>
            {isAuthenticated ?
                <>{children}</>
                :
                isLoading ?
                    <div>Loading ...</div>
                    :
                    <p>Please authenticate first</p>
            }

        </>
    )

}

export default CheckAuthentication;